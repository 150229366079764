import { styled } from 'linaria/react';
import { baseStyles, smallStyles } from './ui/Button';

const FindifyStyling = styled('div')`
  display: contents;
  :global() {
    @media (max-width: 1490px) {
      button.findify-components--swiper__next {
        right: 0;
      }
      button.findify-components--swiper__prev {
        left: 0;
      }
      .findify-recommendation button.findify-components--swiper__next,
      .findify-recommendation button.findify-components--swiper__prev {
        background: rgba(255, 255, 255, 0.75);
      }
    }
    button.findify-components--range-facet__submit {
      ${baseStyles};
      ${smallStyles};
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      &:hover,
      &:focus {
        text-decoration: none;
      }
      > span {
        margin-right: 0;
        font-size: 0.875rem;
        font-weight: 600;
      }
    }
  }
`;

export default FindifyStyling;
