import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../Theme';

export const BaseHeading = `
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 1.4;
  color: ${theme.colors.black};
  text-transform: uppercase;

  &.overlay {
    color: ${theme.colors.white};
  }

  &.text-shadow {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const H1 = styled('h1')`
  ${BaseHeading};
  font-size: 3rem;
`;

export const H2 = styled('h2')`
  ${BaseHeading};
  font-size: 2.25rem;
`;

export const Heading2 = `
  ${BaseHeading};
  font-size: 2.25rem;
`;

export const H3 = styled('h3')`
  ${BaseHeading};
  font-size: 1.5rem;
`;

export const Heading3 = `
  ${BaseHeading};
  font-size: 1.5rem;
`;

export const H4 = styled('h4')`
  ${BaseHeading};
  font-size: 1.125rem;
`;

export const Heading4 = `
  ${BaseHeading};
  font-size: 1.125rem;
`;

export const Heading5 = `
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: ${theme.colors.black};
  text-transform: uppercase;

  &.overlay {
    color: ${theme.colors.white};
  }

  &.text-shadow {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const H5 = styled('h5')`
  ${Heading5};
`;

export const SpecialHeadline = `
  font-family: ${theme.fonts.secondary};
  font-size: 2rem;
  color: ${theme.colors.black};
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25;
`;

export const Quote = styled('blockquote')`
  ${SpecialHeadline};
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  p {
    font: inherit;
  }
`;

export const BaseText = `
  color: ${theme.colors.text};
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5;

  &.text-shadow {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25);
  }
`;

export const BigText = css`
  ${BaseText};
  font-weight: 600;
  font-size: 1.125rem;
  color: ${theme.colors.black};
`;

export const SmallText = css`
  ${BaseText};
  font-size: 0.875rem;
`;

export const SmallTextMedium = css`
  ${BaseText}
  font-weight: 600;
  font-size: 0.875rem;
  color: ${theme.colors.black};
`;

export const MiniText = css`
  ${BaseText};
  font-size: 0.75rem;
`;

export const MiniTextMedium = css`
  ${BaseText}
  font-weight: 600;
  font-size: 0.75rem;
  color: ${theme.colors.black};
`;
