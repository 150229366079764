import React from 'react';
import { styled } from 'linaria/react';

import { ReactComponent as Burger } from '../../../svg/burger.svg';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';

const BurgerButton = styled('button')`
  background: transparent;
  svg {
    width: 20px;
  }
`;

const BurgerButtonComponent = ({ ...rest }) => {
  return (
    <DrawerTrigger id="menu-drawer">
      {drawer => (
        <BurgerButton
          onClick={!drawer.isOpen ? drawer.showTarget : drawer.hideTarget}
          {...rest}
        >
          <Burger />
        </BurgerButton>
      )}
    </DrawerTrigger>
  );
};

export default BurgerButtonComponent;
