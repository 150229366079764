import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const defaultChannelId = 11;

function getLanguage(channel, lang) {
  return channel.languages.find(
    language => language.culture.slice(0, 2) === lang
  );
}

export function useStoryblokTranslatedSlugsToAlternateRoutes({
  translatedSlugs,
  defaultLanguageSlug
}) {
  const { channels } = useContext(ChannelContext);
  if (!translatedSlugs || translatedSlugs.length === 0) return [];
  const alternateRoutes = translatedSlugs
    .map(({ lang, path }) => {
      let foundChannel, language;

      // If the language coming from storyblok culture, then we first
      // look for the country if exist in the channels
      const [langCode, countryCode] = lang.split('-');
      if (countryCode)
        foundChannel = channels.find(({ countries }) =>
          countries.some(
            ({ code }) => code.toLowerCase() === countryCode.toLowerCase()
          )
        );

      if (foundChannel) language = getLanguage(foundChannel, langCode);
      // If channel is not found by culture, we look for channel with the same language
      else
        for (let x = 0; x < channels.length; x++) {
          language = getLanguage(channels[x], lang);
          if (!language) continue;
          foundChannel = channels[x];
          break;
        }

      if (!language) return null;

      const culture = language.culture;
      const channelId = foundChannel.id;
      const route = '/' + (path === 'start-page' ? '' : path);
      return { culture, channelId, route };
    })
    .filter(route => route);

  // Adding default language to alternateRoutes as it is not listed
  // in translatedSlugs
  alternateRoutes.push({
    culture: 'en-GB',
    channelId: defaultChannelId,
    route: defaultLanguageSlug
  });

  return alternateRoutes;
}

function generateDefaultRoute({ alternateRoutes, euRoute }) {
  const route = alternateRoutes.find(
    ({ channelId }) => channelId === defaultChannelId
  )?.route;
  const path = euRoute.url + route;
  return { culture: 'x-default', path };
}

function generateNonEuRoutes({ alternateRoutes, channels, currentCulture }) {
  let nonEuRoutes = [];
  for (let x = 0; x < alternateRoutes.length; x++) {
    const { culture, channelId, route } = alternateRoutes[x];

    // Default route is rendered separately
    if (channelId === defaultChannelId) continue;

    // Exclude current language
    if (culture === currentCulture) continue;

    // Finding the matching baseUrl
    const baseUrl = channels.find(channel =>
      channel.languages.some(lang => lang.culture === culture)
    )?.url;
    if (!baseUrl) continue;

    // Generating path from base url and alternate route
    const path = baseUrl + route;

    nonEuRoutes.push({ culture, path });
  }
  return nonEuRoutes;
}

const Hreflang = ({ alternateRoutes, renderAll }) => {
  const { channels, selectedChannel } = useContext(ChannelContext);

  const currentCulture = selectedChannel.language.culture;
  const euRoute = channels.find(channel => channel.id === defaultChannelId);

  const defaultRoute = generateDefaultRoute({
    alternateRoutes,
    euRoute
  });

  const nonEuRoutes = renderAll
    ? generateNonEuRoutes({
        alternateRoutes,
        channels,
        currentCulture,
        euRoute
      })
    : [];

  const routes = [defaultRoute, ...nonEuRoutes];

  return (
    <Helmet>
      {routes.map(({ culture, path }) => (
        <link key={culture} rel="alternate" hreflang={culture} href={path} />
      ))}
    </Helmet>
  );
};

export default Hreflang;
