import { css } from 'linaria';

import NunitoWoff from './nunito-sans-v6-latin-regular.woff';
import NunitoWoff2 from './nunito-sans-v6-latin-regular.woff2';
import NunitoWoffSemiBold from './nunito-sans-v6-latin-600.woff';
import NunitoWoff2SemiBold from './nunito-sans-v6-latin-600.woff2';
import NunitoWoffBold from './nunito-sans-v6-latin-700.woff';
import NunitoWoff2Bold from './nunito-sans-v6-latin-700.woff2';

import ChronicleWoff from './ChronicleDisp-Roman_Web.woff';
import ChronicleWoff2 from './ChronicleDisp-Roman_Web.woff2';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* AVENIR NEXT */
      @font-face {
        font-family: 'Avenir Next';
        src: local('AvenirNext-Regular'), local('Avenir Next - Regular'),
          local('Avenir Next Regular'), local('Avenir Next');
        font-weight: 400;
        font-style: normal;
      }
      @font-face {
        font-family: 'Avenir Next';
        src: local('AvenirNext-DemiBold'), local('Avenir Next - DemiBold'),
          local('Avenir Next DemiBold');
        font-weight: 600;
        font-style: normal;
      }
      @font-face {
        font-family: 'Avenir Next';
        src: local('AvenirNext-Bold'), local('Avenir Next - Bold'),
          local('Avenir Next Bold');
        font-weight: 700;
        font-style: normal;
      }

      /* NUNITO SANS */
      @font-face {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        src: local(''), url(${NunitoWoff2}) format('woff2'),
          url(${NunitoWoff}) format('woff');
      }
      @font-face {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        src: local(''), url(${NunitoWoff2SemiBold}) format('woff2'),
          url(${NunitoWoffSemiBold}) format('woff');
      }
      @font-face {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        src: local(''), url(${NunitoWoff2Bold}) format('woff2'),
          url(${NunitoWoffBold}) format('woff');
      }

      /* CHRONICLE */
      @font-face {
        font-family: 'HCo Chronicle Display';
        src: url(${ChronicleWoff2}) format('woff2'),
          url(${ChronicleWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
      }
    }
  `;
}
