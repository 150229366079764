import React from 'react';
import { useAddedToCart, useAddedToCartUpdate } from '.';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const Notifications = styled('div')`
  position: relative;
  z-index: 20;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  ${theme.below.md} {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${theme.below.xs} {
    top: auto;
    left: auto;
    bottom: 0;
    transform: none;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`;

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10;
  display: none;

  &.active {
    display: block;
  }
`;

const AddedToCartWrapper = () => {
  const cards = useAddedToCart();
  const { push, clear, clearAll } = useAddedToCartUpdate();
  const exitDuration = 400;
  return (
    cards.length > 0 && (
      <>
        <Overlay
          onClick={_ => clearAll(exitDuration)}
          className={
            cards.filter(entry => entry.state == 'active').length > 0 &&
            'active'
          }
        />
        <Notifications>
          {cards.map(({ Component, id, state, ...props }) =>
            React.cloneElement(Component, {
              id,
              clear,
              state,
              exitDuration,
              ...props
            })
          )}
        </Notifications>
      </>
    )
  );
};

export default AddedToCartWrapper;
