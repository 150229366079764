import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React from 'react';
import { theme } from '../Theme';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;

  a {
    border: 0;
    margin: 0;
    text-decoration: none;
    display: block;

    ${theme.above.sm} {
      margin: 0 0.5rem;
    }

    .product-card-detail {
      border-top: 0;
      text-align: flex-start;
      margin-top: 0.5rem;

      header h3 {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.4;
        color: ${theme.colors.black};
      }

      header h4 {
        font-size: 0.625rem;
        font-weight: 400;
        line-height: 1.6;
        color: ${theme.colors.darkGrey};
      }
    }
  }

  .product-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  img {
    display: block;
    margin: 0;
    padding: 0;
  }

  .price-package-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    .price,
    .new-price,
    .old-price {
      font-size: 0.75rem;
      font-weight: 400;
      color: ${theme.colors.black};
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    .old-price {
      color: ${theme.colors.text};
      margin-left: 0.4rem;
      letter-spacing: 0;
    }

    .new-price {
      color: ${theme.colors.discount};
      letter-spacing: 0.03em;
    }

    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }
  }

  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }

  .badge-top-right {
    top: 3em;
  }

  .badge-top-left {
    font-size: 0.75rem;
    color: ${theme.colors.text};
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '2:3',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  children,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...product.badges];
  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });
  const activeColor = product.customFields
    .find(({ key }) => key === 'color1')
    ?.stringValue.replace('_', ' ');
  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{
          margin: '0 0 0'
        }}
      >
        {hasImages ? (
          <Image
            sizes={imageSizes}
            aspect={imageAspect}
            alt={product.images[0].alt}
            src={product.images[0].url}
          >
            <Badges badges={badges} />
          </Image>
        ) : (
          <Image src={transparentDataImg} />
        )}
        <section className="product-card-detail">
          <header>
            <h3>{product.name}</h3>
            <h4 className="product-color">{activeColor || '—'}</h4>
          </header>
          <div className="price-package-wrapper">
            <Price
              price={product.price}
              previousPrice={product.previousPrice}
            />
          </div>
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
