import React from 'react';
import { css, cx } from 'linaria';
import { render, MARK_LINK } from 'storyblok-rich-text-react-renderer';
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { theme } from '../../Theme';
import MaxWidth from '../MaxWidth';
import FooterLinks from './FooterLinks';
import SocialLinks from './SocialLinks';
import InfoIcons from './InfoIcons';
import { useStoryblokInternationalizedPathname } from '../../Storyblok/storyblok';
import StoryblokRouteQuery from '../../Storyblok/queries/StoryblokRouteQuery.gql';

const MainWrapper = css`
  position: relative;
  background: ${theme.colors.tretornBeige};
  z-index: 0;
`;

const FooterGrid = css`
  width: 100%;
  margin: 0 auto;
  padding: 1.75rem 0;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  grid-template-areas: 'social' 'newsletter' 'links' 'shipping' 'payment';

  ${theme.above.md} {
    grid-column-gap: 16px;
    grid-row-gap: 40px;
    grid-template-columns: 2fr 5fr 5fr;
    grid-template-areas: 'links links newsletter' 'social shipping payment';
    padding: 3.625rem 0 2rem;
  }

  .social {
    grid-area: social;
  }
  .newsletter {
    grid-area: newsletter;
  }
  .links {
    grid-area: links;
  }
  .shipping {
    grid-area: shipping;
  }
  .payment {
    grid-area: payment;
  }
`;

const CopyrightWrapper = css`
  background: ${theme.colors.darkTretornBeige};

  .footer-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    width: 100%;
    padding: 1rem 0;
  }

  .copyright {
    width: max-content;
  }

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
  }

  li {
    padding: 0;
    margin: 0;
    margin-left: 24px;
    padding-left: 24px;
    border-left: 1px solid ${theme.colors.grey};
  }

  p,
  a {
    font-size: 0.75rem;
    line-height: 1.6;
    letter-spacing: 0.01em;
    text-decoration: none;
    color: ${theme.colors.text};
  }

  a:focus,
  a:hover {
    text-decoration: underline;
  }

  ${theme.above.md} {
    ul {
      justify-content: flex-start;
    }
  }

  ${theme.below.md} {
    .footer-info {
      flex-direction: column;
    }

    ul {
      flex-wrap: nowrap;
    }

    li {
      margin-left: 12px;
      padding-left: 12px;
    }

    li:first-of-type {
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
    }
  }
`;

const PoweredByWrapper = css`
  margin: 0.2rem 0;
  p {
    background: ${theme.colors.tretornBeige};
    text-align: center;
    font-size: 0.75rem;
    a {
      color: ${theme.colors.text};
      text-decoration: none;
    }
  }
`;

export const Footer = footerData => {
  return (
    <footer className={MainWrapper}>
      <MaxWidth>
        <div className={FooterGrid}>
          <div className="social">
            <SocialLinks data={footerData.social_media[0]} />
          </div>
          <div className="links">
            <FooterLinks sections={footerData.link_section} />
          </div>
          <div className="shipping">
            <InfoIcons data={footerData.shipping[0]} />
          </div>
          <div className="payment">
            <InfoIcons data={footerData.payment[0]} />
          </div>
        </div>
      </MaxWidth>
      <div className={CopyrightWrapper}>
        <MaxWidth>
          <div className="footer-info">
            <p className="copyright">
              © Copyright {new Date().getFullYear()} Tretorn Sweden AB
            </p>
            {footerData.footer_info &&
              render(footerData.footer_info, {
                markResolvers: {
                  [MARK_LINK]: (children, props) => {
                    const { href, target } = props;
                    return (
                      <a href={href} target={target} rel="noreferrer">
                        {children}
                      </a>
                    );
                  }
                }
              })}
          </div>
        </MaxWidth>
      </div>
      <div className={PoweredByWrapper}></div>
    </footer>
  );
};

export default function StandaloneFooter() {
  const location = useLocation();
  let { data } = useQuery(StoryblokRouteQuery, {
    variables: { path: useStoryblokInternationalizedPathname('footer') }
  });

  if (location.pathname === '/footer') return null;

  if (!data?.StoryblokContentNode?.content) return null;
  return <Footer {...data.StoryblokContentNode.content} />;
}
