import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import React from 'react';
import { Root } from './NavigationPanel';
import { Panels } from './PanelSlider';

export const key = _ => Date.now() + Math.random();

export default function MobileMenu(props) {
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen} left>
          <Panels root={{ component: Root, props }} drawer={drawer} />
        </Drawer>
      )}
    </DrawerTarget>
  );
}
