import React, { useState } from 'react';
import CookieConsent from './CookieConsent';
import MarketSelector from './MarketSelector/MarketSelector';

const Popups = () => {
  const [cookieConsentVisible, setCookieConsentVisible] = useState(false);

  return (
    <>
      {/* <MarketSelector callback={() => setCookieConsentVisible(true)} /> */}
      {cookieConsentVisible && <CookieConsent />}
    </>
  );
};

export default Popups;
