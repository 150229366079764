import React, { useState } from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { ReactComponent as Search } from '../../../svg/search.svg';
import useFindify from '@findify/react-bundle';
import { useHistory } from 'react-router';
import t from '@jetshop/intl';
import { theme } from '../../Theme';
import { Below } from '@jetshop/ui/Breakpoints';
import { ReactComponent as CloseSVG } from '../../../svg/close.svg';
import scrollTo from '../../../utils/scrollTo';

const SearchBarContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 316px;
  width: 100%;
  margin-right: 24px;
`;

const InnerSearchBar = styled('div')`
  width: 100%;
  .cancel {
    opacity: 0;
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0 1rem;
    height: 100%;
    background: none;
    color: ${theme.colors.black};
    transform: translateX(-100%);
  }
  input[type='search'] {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.4s, transform 0.2s;
  }

  ${theme.below.lg} {
    pointer-events: none;
    input[type='search'] {
      opacity: 0;
      transform: translateX(20px);
    }
    .cancel {
      display: block;
    }
    &.isOpen {
      pointer-events: all;
      .cancel {
        opacity: 1;
        transition: opacity 0.4s;
      }
      input[type='search'] {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.4s, transform 0.2s;
      }
    }
  }
`;

const SearchFieldFindify = styled('div')`
  input {
    -webkit-appearance: none;
    font-family: ${theme.fonts.primary}, ${theme.fonts.primaryFallback},
      sans-serif;
    font-size: 16px;
    padding: 0 1rem;
    background: ${theme.colors.lightGrey};
    height: 100%;
    min-height: 42px;
    width: 100%;
    color: ${theme.colors.black};
    border: 0;
    box-shadow: inset 0px 2px 5px 0px #0000000d;
    border-radius: 2px;
    outline-offset: -1px;
    :focus {
      border-radius: 2px;
      outline: 1px solid ${theme.colors.tretornBlue};
    }
    ::placeholder {
      position: relative;
      top: 2px;
      font-size: 16px;
      color: ${theme.colors.text};
    }
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }
  }
`;

const Button = styled('button')`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background: transparent;
  color: inherit;

  &.inactive {
    pointer-events: none;
  }
  .hidden {
    display: none;
  }
  :hover {
    color: ${theme.colors.black};
  }
  svg {
    height: 28px;
    width: 28px;
    path {
      stroke: ${theme.colors.black};
    }
  }
  &.clear {
    padding: 9px 9.5px;
    svg {
      height: 9px;
      width: 9px;
    }
  }
`;

const ToggleButton = ({
  children,
  setSearchOpen,
  searchOpen,
  matches,
  container,
  inputValue,
  setInputValue,
  ...rest
}) => {
  if (inputValue.length > 0 && (searchOpen || !matches)) {
    return (
      <Button className="clear" onClick={e => setInputValue('')} {...rest}>
        <CloseSVG />
        <span className="hidden">{t('Clear')}</span>
      </Button>
    );
  }

  return (
    <Button
      className={matches && searchOpen && 'inactive'}
      onClick={e => {
        e.preventDefault();
        if (matches && !searchOpen) {
          container.current?.focus();
          container.current?.select();
          setSearchOpen(true);
        }
      }}
      {...rest}
    >
      <Search />
      {children}
    </Button>
  );
};

const SearchBar = ({ searchOpen, setSearchOpen = _ => _, placeholder }) => {
  const history = useHistory();
  const [container] = useFindify({ type: 'autocomplete', history });
  const [inputValue, setInputValue] = useState('');
  return (
    <Below breakpoint="lg">
      {matches => {
        if (!matches) {
          setSearchOpen(false);
        }
        return (
          <SearchBarContainer>
            <InnerSearchBar className={cx(searchOpen && 'isOpen')}>
              <button
                className="cancel"
                onClick={e => {
                  e.preventDefault();
                  setSearchOpen(false);
                }}
              >
                {t('Close')}
              </button>
              <SearchFieldFindify>
                <input
                  ref={container}
                  type="search"
                  placeholder={placeholder}
                  value={inputValue}
                  onChange={e => {
                    scrollTo(0, true);
                    setInputValue(e.target.value);
                  }}
                  onClick={() => {
                    scrollTo(0, true);
                  }}
                />
              </SearchFieldFindify>
            </InnerSearchBar>
            <ToggleButton
              setSearchOpen={setSearchOpen}
              searchOpen={searchOpen}
              matches={matches}
              container={container}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </SearchBarContainer>
        );
      }}
    </Below>
  );
};

export default SearchBar;
