import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';

const Counter = styled.span`
  display: inline-flex;
  flex-direction: row;
`;

const CountDown = ({ date, fallback, ...props }) => {
  const endDate = new Date(date);
  const updateTime = () => {
    const now = new Date().getTime();
    const distance = endDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return distance > 0 && { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(updateTime());
  const [timer, setTimer] = useState();

  useEffect(() => {
    setTimer(
      setInterval(() => {
        setTimeLeft(updateTime());
      }),
      1000
    );

    return () => clearInterval(timer);
  }, []);

  return timeLeft ? (
    <Counter {...props}>
      {timeLeft.days ? `${timeLeft.days}d ` : ''}
      {timeLeft.hours ? `${timeLeft.hours}h ` : ''}
      {timeLeft.minutes ? `${timeLeft.minutes}m ` : ''}
      {timeLeft.seconds ? `${timeLeft.seconds}s ` : ''}
    </Counter>
  ) : (
    fallback || null
  );
};
export default CountDown;
