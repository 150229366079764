import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { PreviewContext } from '@jetshop/core/components/PreviewContext';
import { isInsideStoryblokEditorMode } from './storyblok';

export const StoryblokDraftContext = React.createContext();

let isDraftValues;

export default ({ children }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const space_id = params.get('_storyblok_tk[space_id]');
  const token = params.get('_storyblok_tk[token]');
  const timestamp = params.get('_storyblok_tk[timestamp]');
  const language = params.get('_storyblok_lang');

  const { setPreview } = useContext(PreviewContext);
  const previewToken = [space_id, token, timestamp].filter(_ => _).join(',');
  if (!isDraftValues && isInsideStoryblokEditorMode()) {
    isDraftValues = { token: previewToken, language };
    setPreview(previewToken);
  }
  return (
    <StoryblokDraftContext.Provider value={isDraftValues}>
      {children}
    </StoryblokDraftContext.Provider>
  );
};
