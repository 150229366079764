import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Search } from '../../../svg/search.svg';

import { theme } from '../../Theme';
import useFindify from '@findify/react-bundle';
import { useHistory } from 'react-router';
import scrollTo from '../../../utils/scrollTo';

const SearchBarContainer = styled('form')`
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 0 0.5rem;
  position: relative;
  z-index: 1;
  ${theme.above.sm} {
    width: 250px;
    padding: 0.25rem 0.5rem;
  }
`;

const InnerSearchBar = styled('div')`
  ${theme.below.sm} {
    opacity: 0;
  }
`;

const SearchFieldFindify = styled('div')`
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    min-height: 0;
    z-index: -1;
    padding: 0;
    cursor: pointer;

    -webkit-appearance: none;
    font-family: ${theme.fonts.primary}, ${theme.fonts.primaryFallback},
      sans-serif;
    font-size: 16px;
    padding: 0 1rem;
    background: ${theme.colors.lightGrey};
    height: 100%;
    width: 100%;
    color: ${theme.colors.black};
    border: 0;
    box-shadow: inset 0px 2px 5px 0px #0000000d;
    border-radius: 2px;
    outline-offset: -1px;
    :focus {
      border-radius: 2px;
      outline: 1px solid ${theme.colors.tretornBlue};
    }
    ::placeholder {
      position: relative;
      top: 2px;
      font-size: 16px;
      color: ${theme.colors.text};
    }
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }
  }
`;

const Button = styled('button')`
  display: flex;
  align-items: center;
  background: transparent;
  color: inherit;
  pointer-events: none;

  :hover {
    color: ${theme.colors.black};
  }
  svg {
    height: 28px;
    width: 28px;
    path {
      stroke: ${theme.colors.black};
    }
  }
`;

export function SearchButton({ children, ...rest }) {
  return (
    <Button {...rest} aria-hidden="true">
      <Search />
      {children}
    </Button>
  );
}

const SearchBar = ({ className, placeholder }) => {
  const history = useHistory();
  const [container] = useFindify({ type: 'autocomplete', history });
  return (
    <SearchBarContainer className={className}>
      <InnerSearchBar>
        <SearchFieldFindify>
          <input
            ref={container}
            type="search"
            placeholder={placeholder}
            onInput={() => {
              scrollTo(0, true);
            }}
            onClick={() => {
              scrollTo(0, true);
            }}
          />
        </SearchFieldFindify>
      </InnerSearchBar>
      <SearchButton />
    </SearchBarContainer>
  );
};

export default SearchBar;
