import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import PagePreviewQuery from './PagePreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { theme } from './Theme';
import Popups from './Popups/Popups';
import DynamicRouteHandler from './DynamicRouteHandler';
import StoryblokIsDraftProvider from './Storyblok/StoryblokDraftContext';
import FindifyProvider from './Findify';
import { ProductPage as LoadableProductPage } from './ProductPage/ProductPage.loadable';
import { NotFound } from './NotFoundPage.loadable';
import { NavTreePage } from './NavigationTree/NavTreePage.loadable';
import { PreviewRoute } from './PreviewRoute.loadable';
import LoadableStartPage from './StartPage/StartPage.loadable';
import { CategoryPage as LoadableCategoryPage } from './CategoryPage/CategoryPage.loadable';
import { ContentPage as LoadableContentPage } from './ContentPage/ContentPage.loadable';
import { SearchPage as LoadableSearchPage } from './SearchPage/SearchPage.loadable';
import { AddedToCartNotificationProvider } from './Cart/AddedToCartNotification';
import '../globalStyles';

function Shop() {
  const { routes } = useShopConfig();
  const shouldRenderGoogleVerification = channel => {
    const {
      country: { code }
    } = channel;

    switch (code) {
      case 'DE':
      case 'GB':
      case 'ES':
      case 'IT':
      case 'NL':
      case 'SE':
        return true;
      default:
        return false;
    }
  };
  const getFacebookVerificationContentHash = channel => {
    const {
      country: { code }
    } = channel;

    switch (code) {
      case 'ES':
        return '92wqw3xsymh2tm1le0meukzpzxpuyw';
      case 'GB':
        return 'jgzh3p8dh59mfnujq4j6o2o8ygm8iv';
      case 'NL':
        return 'd8a2cb30w8bw4rknz6ek0a9517bkly';
      case 'FR':
        return 'zxcwvo84q3fotqqtb8uuyvf4hov3io';
      case 'IT':
        return 'dl39bhha8l0pv32u1i4the3ppq44qb';
      case 'FI':
        return 'f1m7x7yxtr3709l41yx0cebl14fbxy';
      case 'NO':
        return '17001v08ybs9vmi4vnksci1xww6vec';
      case 'DE':
        return 'iwj2ej3wyzsp00lwgzydit8s22es6a';
      case 'DK':
        return 'stqck7b3e9p4jaimxboso7c0r3zxev';
      case 'EU':
        return 'sbgxjfcqrsrzd9kv9hyggzsi2rytnd';
      case 'SE':
      default:
        return '0qb3p163ytklxc6iaw62q4ty14tdvo';
    }
  };
  const shouldRenderTestFreaks = channel => {
    const {
      country: { code },
      url
    } = channel;

    if (url === 'https://www.tretorn.eu') {
      return 'tretorn.eu';
    }
    switch (code) {
      case 'SE':
        return 'tretorn.com-se';
      case 'DE':
        return 'tretorn.com-de';
      case 'GB':
        return 'tretorn.com-gb';
      case 'EU':
        return 'tretorn.eu';
      case 'DK':
        return 'tretorn.dk';
      case 'NO':
        return 'tretorn.no';
      case 'FI':
        return 'tretorn.fi';
      default:
        return false;
    }
  };

  return (
    <GenericError>
      <FindifyProvider>
        <ModalProvider>
          <AddedToCartNotificationProvider>
            <StoryblokIsDraftProvider>
              <Container>
                <LoadingBar color={theme.colors.tretornYellow} />
                <CustomFont
                  primaryFont={theme.fonts.primary}
                  injectCss={loadFontCss}
                />
                <ProductListProvider queries={productListQueries}>
                  <ChannelContext.Consumer>
                    {({ selectedChannel }) => {
                      const facebookVerificationContentHash = getFacebookVerificationContentHash(
                        selectedChannel
                      );
                      const renderGoogleVerificationContent = shouldRenderGoogleVerification(
                        selectedChannel
                      );
                      return (
                        <Helmet
                          htmlAttributes={{
                            lang: selectedChannel?.language?.culture
                          }}
                        >
                          {renderGoogleVerificationContent && (
                            <meta
                              name="google-site-verification"
                              content="ZRgqMBL5O51VCb2xNSgWtSB1Ifl3riQWMrxivZzGnts"
                            />
                          )}
                          <meta
                            name="facebook-domain-verification"
                            content={facebookVerificationContentHash}
                          />
                          {shouldRenderTestFreaks(selectedChannel) && (
                            <script
                              async
                              src={`https://js.testfreaks.com/onpage/${shouldRenderTestFreaks(
                                selectedChannel
                              )}/head.js`}
                            ></script>
                          )}
                        </Helmet>
                      );
                    }}
                  </ChannelContext.Consumer>
                  <Header />
                  <Content>
                    <PaginationProvider defaultProductsPerPage={24}>
                      <Switch>
                        <Route path="/favourites">
                          <Favourites />
                        </Route>
                        <Route path={routes.search.path}>
                          <LoadableSearchPage />
                        </Route>
                        <Route path={routes.tree.path}>
                          <NavTreePage />
                        </Route>
                        <Route
                          path="/preview"
                          render={props => (
                            <PreviewRoute
                              productQuery={ProductPreviewQuery}
                              categoryQuery={CategoryPreviewQuery}
                              pageQuery={PagePreviewQuery}
                              productPage={LoadableProductPage}
                              categoryPage={LoadableCategoryPage}
                              contentPage={LoadableContentPage}
                              StartPage={LoadableStartPage}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          render={props => (
                            <DynamicRouteHandler
                              routeQuery={routeQuery}
                              productPage={LoadableProductPage}
                              categoryPage={LoadableCategoryPage}
                              contentPage={LoadableContentPage}
                              notFoundPage={NotFound}
                              LoadingPage={LoadingPage}
                              {...props}
                            />
                          )}
                        />
                      </Switch>
                    </PaginationProvider>
                  </Content>
                  <Footer />
                </ProductListProvider>
                <ModalRoot />
                <ScrollRestorationHandler
                  ignoreForRouteTypes={[
                    'sortOrderChange',
                    'filterChange',
                    'paginationChange',
                    'FindifyUpdate'
                  ]}
                />
                <Popups />
              </Container>
            </StoryblokIsDraftProvider>
          </AddedToCartNotificationProvider>
        </ModalProvider>
      </FindifyProvider>
    </GenericError>
  );
}

export default Shop;
