import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { waitForFindify } from '@findify/react-bundle';
import { styled } from 'linaria/react';
import { theme } from './Theme';
import useFindify from '@findify/react-bundle';
import { useLocation, useHistory } from 'react-router';
import FindifyStyling from './FindifyStyling';

// LOADER FOR THE DIFFERENT CHANNELS
export const FindifyLoader = () => {
  const selectedChannel = useContext(ChannelContext)?.selectedChannel;

  const findifyDevAccount = 'tretorn-staging.se';
  const findifyAccounts = {
    channel_1: 'tretorn.se', // SE
    channel_2: 'tretorn.dk', // DK
    channel_3: 'tretorn.de', // DE
    channel_4: 'tretorn.es', // ES
    channel_5: 'tretorn.fr', // FR
    channel_6: 'tretorn.uk', // GB
    channel_7: 'tretorn.it', // IT
    channel_8: 'tretorn.nl', // NL
    channel_9: 'tretorn.no', // NO
    channel_10: 'tretorn.fi', // FI
    channel_11: 'tretorn.eu' // EU
  };

  const channelId = selectedChannel.id;
  let accountId = findifyAccounts['channel_' + channelId];

  // ALWAYS USE FINDIFY STAGING ACCOUNT ON TEST SERVER AND STAGING
  if (typeof window !== 'undefined') {
    if (window.location.host.match(/test|dev/i)) {
      accountId = findifyDevAccount;
    }
  }

  return selectedChannel ? (
    <Helmet>
      <script
        id="findify-script"
        src={`//assets.findify.io/${accountId}.min.js`}
        async="true"
        defer="true"
        type="text/javascript"
      />
    </Helmet>
  ) : null;
};

// HOW TO TRANSFORM STANDARD ARTICLE NUMBER INTO BASE ITEM ID
// (THIS CAN VARY BETWEEN STORES, FIND OUT WHAT IS NEEDED)
const getItemId = articleNumber => {
  // NO TRANSFORMATION
  return articleNumber;
};

// FINDIFY PAGE VIEW TRACKING
export const FindifyTracker = ({ isProdPage, product, selectedVariation }) => {
  const findifyTrackingContext = useContext(FindifyTrackingContext);
  const location = useLocation();
  const { pathname, search } = location;

  const triggerFindifyTracking = async () => {
    const { analytics } = await waitForFindify();

    // REGULAR PAGES, JUST SEND VIEW PAGE EVENT
    if (!isProdPage) {
      analytics.sendEvent('view-page');
    }
    // PRODUCT PAGE - SEND MORE
    else {
      // TRANSFORM ARTICLE NUMBER IF NECESSARY
      const baseItemId = getItemId(product.articleNumber);

      // USE FIRST VARIANT IF NO SELECTED VARIANT
      // USE REGULAR ART NO AS VARIANT ID FOR PRODS WITHOUT VARIANTS
      let findifyVariantId = selectedVariation
        ? selectedVariation.articleNumber
        : null;
      if (!findifyVariantId) {
        if (product.variants?.values.length) {
          findifyVariantId = product.variants.values[0].articleNumber;
        } else {
          findifyVariantId = product.articleNumber;
        }
      }
      const trackingObject = {
        item_id: baseItemId,
        variant_item_id: findifyVariantId
      };

      analytics.sendEvent('view-page', trackingObject);
    }
  };

  useEffect(() => {
    if (pathname !== findifyTrackingContext?.lastTrackedPath) {
      if (!isProdPage || product) {
        // STORE TRACKED PATHNAME
        findifyTrackingContext?.setLastTrackedPath(pathname);

        // TRIGGER TRACKING
        triggerFindifyTracking();
      }
    }
  });
  return null;
};

// FINDIFY CART TRACKER, CLUNKY SETUP B/C CART ITEM STATE
// JUMPS AROUND BEFORE SETTLING ON NEW VALUE
let cartItemQtyCounter = 0;
let timeoutVar = null;
export const FindifyCartTracker = ({ cartData }) => {
  clearTimeout(timeoutVar);
  timeoutVar = setTimeout(function() {
    if (cartData && cartData.totalQuantity !== cartItemQtyCounter) {
      cartItemQtyCounter = cartData.totalQuantity;
      triggerFindifyCartTracking(cartData.items);
    }
  }, 1000);
  return null;
};

const triggerFindifyCartTracking = async cartItems => {
  let lineItems = [];
  cartItems.forEach((item, index) => {
    const baseItemId = getItemId(item.articleNumber);

    lineItems.push({
      item_id: baseItemId,
      quantity: item.quantity,
      unit_price: item.product.price.incVat,
      variant_item_id: item.articleNumber
    });
  });

  const { analytics } = await waitForFindify();
  analytics.sendEvent('update-cart', {
    line_items: lineItems
  });
};

// FINDIFY RECOMMENDATIONS
const FindifyRecommendationWrapper = styled('div')`
  padding: 1rem 0;
  width: 100%;
`;

export const FindifyRecommendations = ({ recIdString, product }) => {
  if (!recIdString) {
    console.error('Findify Recommendation widget needs id argument');
  }

  let findifyObject = {
    type: 'recommendation',
    config: {
      slot: recIdString // EXAMPLE: 'product-findify-rec-12'
    }
  };

  // ON PRODUCT PAGE - INCLUDE PRODUCT DATA
  const articleNumber = getItemId(product?.articleNumber);
  if (articleNumber) {
    findifyObject.options = {
      item_ids: [articleNumber]
    };
  }

  const [container, isReady, hasError] = useFindify(findifyObject);

  return (
    <FindifyRecommendationWrapper>
      <div ref={container} />
    </FindifyRecommendationWrapper>
  );
};

// TRACKING CONTEXT - FOR AVOIDING MULTIPLE IDENTICAL VIEW PAGE EVENTS BEING SENT
const FindifyTrackingContext = React.createContext(null);

const FindifyProvider = ({ children }) => {
  const [lastTrackedPath, setLastTrackedPath] = useState('');
  const history = useHistory();

  const goToRoute = route => {
    history.push(route);
  };

  useEffect(() => {
    window.goToRoute = goToRoute;
  });

  return (
    <FindifyTrackingContext.Provider
      value={{ lastTrackedPath, setLastTrackedPath }}
    >
      <FindifyLoader />
      <FindifyStyling>{children}</FindifyStyling>
    </FindifyTrackingContext.Provider>
  );
};

export default FindifyProvider;
