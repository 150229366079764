import React from 'react';
import { styled } from 'linaria/react';
import { SmallTextMedium } from '../../ui/Typography';
import { theme } from '../../Theme';

const Wrapper = styled('div')`
  span {
    display: flex;
    ${theme.below.md} {
      justify-content: center;
      margin-top: 25px;
    }

    ${theme.below.sm} {
      justify-content: center;
    }

    ${theme.below.xs} {
      justify-content: center;
    }
  }

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;

    ${theme.below.md} {
      justify-content: center;
    }

    ${theme.below.xs} {
      flex-wrap: wrap;
    }
  }

  li {
    margin: 0;

    ${theme.below.md} {
      margin: 1.5rem 0 2em;
    }
  }

  li + li {
    margin-left: 2.5rem;
  }

  img {
    display: block;
    max-width: 100%;
  }
`;

const InfoIcons = ({ data }) => {
  if (!data || data.icons.length === 0) return null;
  return (
    <Wrapper>
      <span className={SmallTextMedium}>{data.title}</span>
      <ul>
        {data.icons.map(icon => (
          <li key={icon._uid}>
            <img
              src={icon.icon.filename}
              alt={icon.alt || ''}
              width={icon.icon_size}
              style={{ width: `${icon.icon_size}px` }}
            />
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default InfoIcons;
