import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Helmet } from 'react-helmet-async';

import DynamicFlightRoute from '@jetshop/core/components/DynamicRoute';
import DynamicStoryblokStory from './Storyblok/DynamicStoryblokStory';

import {
  useStoryblokAppsettings,
  useStoryblokInternationalizedPathname
} from './Storyblok/storyblok';

import StoryblokRouteQuery from './Storyblok/queries/StoryblokRouteQuery.gql';
import { StoryblokDraftContext } from './Storyblok/StoryblokDraftContext';
import { defaultLocale } from '../shop.config';

const DynamicRouteHandler = ({ LoadingPage, ...props }) => {
  const isDraft = useContext(StoryblokDraftContext);

  const slug = (props.location.pathname == '/'
    ? 'start-page'
    : props.location.pathname
  ).replace(/^\/+|\/+$/g, '');

  const { data, loading, error } = useQuery(StoryblokRouteQuery, {
    variables: {
      path: useStoryblokInternationalizedPathname(slug),
      relations: 'articles,journal.articles'
    },
    fetchPolicy: isDraft ? 'no-cache' : 'cache-first'
  });

  useStoryblokAppsettings();
  if (loading) {
    return <LoadingPage />;
  }

  if (data?.StoryblokContentNode?.content) {
    const story = data.StoryblokContentNode.content;
    const translatedSlugs = data.StoryblokContentNode.translated_slugs;
    const hero = data.hero?.content?.hero_image?.[0];
    const defaultLanguageSlug =
      slug === 'start-page'
        ? '/'
        : '/' + data.StoryblokContentNode.default_full_slug;

    const title =
      story.meta?.title ||
      (story.page_title ? `${story.page_title} | Tretorn` : undefined);
    const meta = {
      ...story.meta,
      title,
      og_title: story.meta?.og_title || title,
      og_description: story.meta?.og_description || story.meta?.description,
      og_image: story.meta?.og_image || hero?.image?.filename
    };

    let attributes = {};
    if (isDraft?.language) {
      attributes.htmlAttributes = {
        lang: isDraft.language == 'default' ? defaultLocale : isDraft.language
      };
    }
    return (
      <>
        <Helmet {...attributes}>
          {meta.title && <title>{meta.title}</title>}
          {meta.description && (
            <meta name="description" content={meta.description} />
          )}
          {meta.og_title && (
            <meta property="og:title" content={meta.og_title} />
          )}
          {meta.og_description && (
            <meta property="og:description" content={meta.og_description} />
          )}
          {meta.og_type && <meta property="og:type" content={meta.og_type} />}
          {meta.og_image && (
            <meta property="og:image" content={meta.og_image} />
          )}
          {meta.og_url && <meta property="og:url" content={meta.og_url} />}
        </Helmet>
        <DynamicStoryblokStory
          story={story}
          translatedSlugs={translatedSlugs}
          defaultLanguageSlug={defaultLanguageSlug}
        />
      </>
    );
  }

  return <DynamicFlightRoute {...props} LoadingPage={LoadingPage} />;
};

export default DynamicRouteHandler;
