import React, { useState } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';
import { withCookies } from 'react-cookie';
import { theme } from '../Theme';

const animationLength = 800;

const Wrapper = styled('div')`
  bottom: 0;
  position: fixed;
  position: sticky;
  width: 100%;
  justify-content: space-evenly;

  padding: 0.375rem;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  opacity: 0;
  animation-name: enter;
  animation-duration: ${animationLength}ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  z-index: 50;
  &.unmounting {
    animation-name: exit;
  }

  @keyframes enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes exit {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  padding-left: calc((100% - 1440px) / 2 + 3.5rem);
  @media (max-width: 1440px) {
    padding-left: 3.5rem;
  }
  ${theme.below.md} {
    padding: 0.75rem 1rem;
    padding-left: 2rem;
  }
  ${theme.below.sm} {
    padding-left: 1rem;
  }

  p {
    color: ${theme.colors.black};
    font-weight: 400;
    margin-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.3;
  }

  a {
    margin-bottom: 0;
    color: ${theme.colors.text};
  }

  button {
    height: 30px;
    width: 120px;
    border-radius: 2px;
    flex-shrink: 0;
    white-space: nowrap;
  }
`;

const CookieConsent = ({ cookies }) => {
  const { cookieConsent, cookieConsentReset } = cookies.cookies;

  const [visible, setVisible] = useState(cookieConsent !== 'agreed');
  const [unmounting, setUnmounting] = useState(false);

  function setCookies() {
    cookies.set('cookieConsent', `agreed`, { maxAge: 60 * 60 * 24 * 30 });
    cookies.set('cookieConsentReset', `true`, {
      maxAge: 60 * 60 * 24 * 365 * 10
    });
  }

  function agree() {
    setCookies();
    setUnmounting(true);
    setTimeout(() => {
      setVisible(false);
    }, animationLength);
  }

  // The maxAge of the cookie for cookie consent was reduced
  // to 30 days. This functionality exist to override those
  // cookies which were set before the reduced maxAge.
  if (cookieConsent === 'agreed' && !cookieConsentReset) setCookies();

  return visible ? (
    <Wrapper className={unmounting ? 'unmounting' : ''}>
      <p>
        {t('This website uses cookies to ensure you get the best experience.')}{' '}
        <Link to="/legal/cookie-policy">{t('Learn more')}</Link>
      </p>
      <Button onClick={agree}>{t('Got it!')}</Button>
    </Wrapper>
  ) : null;
};

export default withCookies(CookieConsent);
