import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';

import Selector from './Selector';
import { ReactComponent as ChevronSVG } from '../../../../svg/chevron.svg';

import { theme } from '../../../Theme';

const ChannelSelectorWrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const ChannelSelectorButton = styled('button')`
  position: relative;
  z-index: 5;
  color: inherit;

  svg {
    margin: 0 0.5rem;
    width: 10px;
    transition: transform 200ms;
    path {
      stroke: currentColor;
    }
  }

  &.isActive {
    svg {
      transform: rotate(-180deg);
    }
  }
`;

const Flyout = styled('div')`
  background-color: white;
  position: absolute;
  z-index: 5;
  top: 100%;
  right: 0;
  box-shadow: 0px 4px 50px 0px #0000000d;
  border-radius: 0 0 2px 2px;
  min-width: 200px;
  overflow: hidden;
`;

const ChannelSelector = () => {
  return (
    <ChannelContext.Consumer>
      {({ channels, selectedChannel, updateChannel }) => (
        <ChannelSelectorWrapper>
          <FlyoutTrigger id="channel-selector">
            {({ showTarget, hideTarget, isOpen }) => (
              <ChannelSelectorButton
                className={cx(isOpen && 'isActive')}
                data-testid="channel-selector-button"
                onClick={isOpen ? hideTarget : showTarget}
                style={{ background: 'transparent' }}
              >
                {selectedChannel.country.name} ({selectedChannel.currency.name})
                <ChevronSVG />
              </ChannelSelectorButton>
            )}
          </FlyoutTrigger>
          <FlyoutTarget id="channel-selector">
            {({ isOpen, hideTarget }) => (
              <Flyout>
                <Selector
                  channels={channels}
                  hideTarget={hideTarget}
                  selectedChannel={selectedChannel}
                  updateChannel={updateChannel}
                />
              </Flyout>
            )}
          </FlyoutTarget>
        </ChannelSelectorWrapper>
      )}
    </ChannelContext.Consumer>
  );
};

export default ChannelSelector;
