import React, { useContext } from 'react';
import { cx } from 'linaria';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import PanelWrapper from './PanelWrapper';
import { key } from '.';
import { Link } from 'react-router-dom';
import { handleLink } from '../../../Storyblok/storyblok';
import { ReactComponent as ChevronSVG } from '../../../../svg/chevron.svg';
import { LanguageSelectorPanel } from './LanguageSelector';

export const Panel = ({ props, helpers }) => {
  const { push, pop, drawer, current, back, index } = helpers;

  if (!props) return null;

  const pushPanel = (e, data) => {
    e.preventDefault();
    push(Panel, data);
  };

  return (
    <PanelWrapper drawer={drawer} pop={pop} push={push} back={!!index}>
      <ul>
        {props.title && (
          <li className={cx('title', props.component)}>
            <Link to={handleLink(props.link)} onClick={drawer.hideTarget}>
              {props.title}
            </Link>
          </li>
        )}
        {props.featured?.map(link => {
          return (
            <li key={link._uid} className={cx('featured', link.component)}>
              <Link
                to={handleLink(link.link)}
                onClick={e => {
                  if (link.__custom) {
                    push(link.__custom.component, link.__custom.props);
                  } else {
                    pushPanel(e, {
                      ...link,
                      featured: link.featured_subcategories,
                      categories: link.subcategories
                    });
                  }
                }}
              >
                {link.title}
                <ChevronSVG />
              </Link>
            </li>
          );
        })}
        {props.categories?.map(link => {
          return (
            <li key={link._uid} className={cx('category', link.component)}>
              {link.__custom ? (
                <button
                  className="category-button"
                  onClick={e => {
                    push(link.__custom.component, link.__custom.props);
                  }}
                >
                  {link.title}
                  <ChevronSVG />
                </button>
              ) : (
                <Link to={handleLink(link.link)} onClick={drawer.hideTarget}>
                  {link.title}
                </Link>
              )}
              {link.nested_links && (
                <ul className="subcategories-list">
                  {link.nested_links?.map(nested_link => {
                    return (
                      <li key={nested_link._uid}>
                        <Link
                          to={handleLink(nested_link.link)}
                          onClick={drawer.hideTarget}
                        >
                          {nested_link.text}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          );
        })}

        {props.nested_links &&
          props.nested_links?.map(link => {
            return (
              <li key={link._uid} className={cx(link.component)}>
                <Link to={handleLink(link.link)} onClick={drawer.hideTarget}>
                  {link.text}
                </Link>
              </li>
            );
          })}
      </ul>
    </PanelWrapper>
  );
};

export const Root = ({ props, helpers }) => {
  const { selectedChannel } = useContext(ChannelContext);

  const featured = [];
  const categories = [];

  props.links?.forEach(item => {
    if (item.component == 'category') {
      featured.push(item);
    } else {
      categories.push(item);
    }
  });
  categories.push({
    __custom: {
      component: LanguageSelectorPanel,
      props: {}
    },
    _uid: key(),
    component: 'customcategory',
    title: `${selectedChannel.country.name} (${selectedChannel.currency.name})`
  });
  return <Panel props={{ featured, categories }} helpers={helpers} />;
};
