// storyblok documentation
// https://www.storyblok.com/docs/image-service
import React, { useRef } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { useInView } from 'react-intersection-observer';

const ImageWrapper = styled('div')`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.fillAvailableSpace {
    height: 100%;
  }

  &.aspect {
    width: 100%;
    height: auto;
    padding-bottom: var(--aspect);
    img {
      width: 100%;
      height: 100%;
    }
  }

  &.cover {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.contain {
    img {
      object-fit: contain;
    }
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  [data-storyblok-preview] {
    opacity: 0.6;
  }

  [data-storyblok-image] {
    opacity: 0;
    transition: opacity 0.5s;
    &.cached {
      transition: none;
      opacity: 1;
    }
    &.loaded {
      opacity: 1;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  [data-storyblok-image-children] {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const Filters = class {
  constructor() {
    this.filters = new Map();
  }
  get length() {
    return this.filters.size;
  }
  add(type, value) {
    if (typeof type !== 'string' || typeof value !== 'string') return;
    this.filters.set(type, `${type}(${value})`);
  }
  toString() {
    return Array.from(this.filters.values()).join(':');
  }
};

const nativeLazyLoading =
  typeof HTMLImageElement !== 'undefined' &&
  'loading' in HTMLImageElement.prototype;

const imageCache = {};

const isCached = src => src in imageCache;

const cachedImageRef = src => {
  if (!src) return;
  if (isCached(src)) return imageCache[src];
  imageCache[src] = true;
};

export const imageSrc = ({
  src,
  quality,
  width = 0,
  height = 0,
  background,
  filetype = 'jpeg',
  smart,
  fitin,
  imageAspect
}) => {
  let srcOriginal = 'https://a.storyblok.com';
  const srcImageService = 'https://img2.storyblok.com';
  const photoId = src.match(/\/f.*/)[0];

  const filters = new Filters();
  if (background) {
    filters.add('fill', background);
  }
  filters.add('format', filetype);
  filters.add('quality', quality);

  // Variable width of image
  if (width !== 0 && height === 0) {
    // Generate multiple images depending on width of screen and on
    // given max width (width)
    const fluidSizes = [];
    fluidSizes.push(width);
    fluidSizes.push(Math.round(width / 4));
    fluidSizes.push(Math.round(width / 2));
    fluidSizes.push(Math.round(width * 1.5));
    fluidSizes.push(Math.round(width * 2));

    // Given imageAspect, crop the fluid picture
    if (imageAspect) {
      const [aw, ah] = imageAspect.split(/[^\d]/).map(Number);
      imageAspect = ah / aw;
    }

    const fluidSrcSet = fluidSizes
      .sort((a, b) => a - b)
      .map(
        fluidSize =>
          `${srcImageService}${fitin ? '/fit-in' : ''}/${fluidSize}x${
            imageAspect ? Math.round(fluidSize * imageAspect) : 0
          }/filters:${filters}${photoId} ${fluidSize}w`
      )
      .join(', ');

    return fluidSrcSet;
  }

  const params = [];
  if (fitin) {
    params.push('fit-in');
  }
  if (width !== 0 && height !== 0) {
    params.push(`${width}x${height}`);
    if (smart) {
      params.push('smart'); // The config smart can only be set when width and height are defined
    }
  }
  params.push(`filters:${filters}`);
  // NOTE: The configurations have to come in a specific order
  // Check storyblok documentation
  src = src.replace(srcOriginal, [srcImageService, params.join('/')].join('/'));
  return src;
};

const Image = ({
  src,
  alt,
  filetype = 'jpeg',
  quality = 80,
  width = 0,
  height = 0,
  sizes,
  imageAspect,
  background,
  smart,
  fitin,
  cover,
  fillAvailableSpace,
  lazy = true,
  originalConstraints = true,
  className,
  aspect,
  children,
  ...restProps
}) => {
  if (!src) return null;
  const imageSizes = sizes
    ? sizes
    : `(max-width: ${width}px) 100vw, ${width}px`;

  const pictureRef = useRef();
  const sourceRef = useRef();
  let imageRef = useRef();

  // Prop aspect used for wrapper
  if (aspect) {
    const [aw, ah] = aspect.split(/[^\d]/).map(Number);
    aspect = (ah / aw) * 100;
  }

  width = Number(width);
  height = Number(height);

  const [_, originalWidth, originalHeight] = src
    ?.match(/([0-9]+)x([0-9]+)/)
    .map(Number);

  if (originalConstraints) {
    width = Math.min(width, originalWidth);
    height = Math.min(height, originalHeight);
  }

  const objectFit = cover ? 'cover' : fillAvailableSpace ? 'cover' : 'contain';

  const props = {
    src,
    filetype,
    quality: String(quality),
    width,
    height,
    background,
    filetype,
    smart,
    fitin,
    imageAspect
  };

  const preview = imageSrc({
    ...props,
    filetype: 'jpeg',
    width: 100
  });
  const img = imageSrc(props);
  const webp = imageSrc({ ...props, filetype: 'webp' });

  const load = () => {
    pictureRef.current.classList.add('loaded');
    cachedImageRef(img.toString());
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
    skip: nativeLazyLoading
  });

  return (
    <ImageWrapper
      ref={ref}
      data-storyblok-image-container
      className={cx(className, objectFit, aspect && 'aspect')}
      style={{
        '--aspect': fillAvailableSpace ? 0 : !aspect ? '100%' : aspect + '%'
      }}
      {...restProps}
    >
      <picture data-storyblok-preview>
        <source
          srcSet={preview}
          sizes={imageSizes}
          type="image/webp"
          ref={sourceRef}
        />
        <img
          width={originalWidth}
          height={originalHeight}
          src={src}
          sizes={imageSizes}
          alt={alt || ''}
          ref={imageRef}
          {...restProps}
        />
      </picture>
      <picture
        data-storyblok-image
        className={cx(isCached(img.toString()) && 'cached')}
        ref={pictureRef}
      >
        <source
          srcSet={webp}
          sizes={imageSizes}
          type="image/webp"
          ref={sourceRef}
        />
        {(!lazy || nativeLazyLoading || inView) && (
          <img
            width={originalWidth}
            height={originalHeight}
            onLoad={load}
            src={src}
            sizes={imageSizes}
            alt={alt || ''}
            ref={imageRef}
            {...restProps}
            loading={lazy ? 'lazy' : 'eager'}
          />
        )}
      </picture>
      {children && <div data-storyblok-image-children>{children}</div>}
    </ImageWrapper>
  );
};

export default Image;
