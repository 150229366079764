import React from 'react';
import Spinner from '../components/ui/Spinner';
import { styled } from 'linaria/react';
const LoadingPageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const LoadingPage = () => (
  <LoadingPageWrapper>
    <Spinner />
  </LoadingPageWrapper>
);
export default LoadingPage;
