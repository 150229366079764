import { create } from 'zustand';

export const useAppSettingsStore = create(set => ({
  shippingAndReturns: '',
  freeShippingReturns: '',
  pdpSpecExcludeList: [],
  setShippingAndReturns: str => set(state => ({ shippingAndReturns: str })),
  setFreeShippingReturns: str => set(state => ({ freeShippingReturns: str })),
  setPdpSpecExcludeList: arr => set(state => ({ pdpSpecExcludeList: arr }))
}));
