import { theme } from '../Theme';

export const dropdownStyles = `
  [data-flight-dropdown-items] {
    top: calc(100% - 1px);
    background: ${theme.colors.offWhite};
    border: 1px solid ${theme.colors.tretornBlue};
    border-top: 0;
    border-radius: 0 0 3px 3px;
    z-index: 999;
    width: 100%;
  }
  [data-flight-dropdown-item] {
    overflow: hidden;
    display: block;
    padding: 0.75em;
    border-top: 1px solid ${theme.colors.lightGrey};
    width: 100%;
    text-align: left;
    background: transparent;
    display: flex;
    align-items: center;
    span + * {
      margin-left: auto;
    }
    /* qty text */
    span + span {
      padding-left: 0.5em;
      font-size: 80%;
    }
    :hover,
    :focus {
      background: white;
      color: ${theme.colors.tretornBlue};
      outline: none;
    }
  }

  [data-flight-dropdown-button] {
    padding: 0.75em;
    border: 1px solid #dedede;
    width: 100%;
    text-align: left;
    background: ${theme.colors.white};
    font-weight: bold;
    border-radius: 3px;
    outline: none;
    display: flex;
    align-items: center;

    svg {
      margin-left: auto;
    }

    :focus {
      border: 1px solid ${theme.colors.tretornBlue};
    }

    &[aria-expanded='true'] {
      border-radius: 3px 3px 0 0;
      border-color: ${theme.colors.tretornBlue};

      .carot {
        transform: rotate(180deg);
      }
    }
  }
`;
