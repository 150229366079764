import React from 'react';
import { styled } from 'linaria/react';

import t, { useIntl } from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import { Currency } from '@jetshop/ui/Price/Currency';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { useChannelSettings } from '@jetshop/core/hooks/Channels/useChannelSettings';

import CartItem from './CartItem';
import Button, { ExternalLinkButton } from '../ui/Button';
import FreeShipping from './FreeShipping';
import { ReactComponent as Close } from '../../svg/close.svg';

import { theme } from '../Theme';
import { FindifyRecommendations } from '../Findify';

const ViewWrapper = styled('div')`
  padding: 20px;
  max-width: 392px;
`;

const Header = styled('header')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 5px 0;
  font-size: 1.125rem;
`;

const MainHeading = styled('h2')`
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;
const Heading = styled('h4')`
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: ${theme.colors.text};
`;

const ProductCount = styled('p')`
  font-size: 0.75rem;
  letter-spacing: 0.01em;
  color: ${theme.colors.text};
`;

const CartItems = styled('section')``;
const Summary = styled('section')``;
const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CartSummary = styled('div')`
  margin: 0.5rem 0;
  padding: 0.825rem 0;
  border-top: 1px solid ${theme.colors.lightGrey};
  color: ${theme.colors.black};
  > div {
    padding: 0.5rem 0;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .primary {
    font-weight: 600;
    font-size: 0.875rem;
  }
  .secondary {
    font-size: 0.75rem;
    letter-spacing: 0.01em;
  }
`;
const Checkout = styled(ExternalLinkButton)`
  margin-bottom: 1rem;
  width: 100%;
`;

const ContinueShopping = styled(Button)`
  width: 100%;
`;

const CartEmpty = styled('p')`
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
`;

const CloseButton = styled('button')`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 20px;
  height: 20px;
  line-height: 0;

  svg {
    width: 14px;
  }
`;

const ItemCount = ({ count }) => (
  <ProductCount>
    {t(`{count, plural, =0 {} one {1 item} other {{count} items}}`, { count })}
  </ProductCount>
);

const CartHeader = ({ itemCount }) => {
  const intl = useIntl();
  return (
    <Header>
      <MainHeading>{t('Cart')}</MainHeading>
      <ItemCount count={itemCount} />
      <DrawerTrigger preventOverflow={true} id="cart-drawer">
        {drawer => (
          <CloseButton onClick={drawer.hideTarget} aria-label={intl('Close')}>
            <Close />
          </CloseButton>
        )}
      </DrawerTrigger>
    </Header>
  );
};

const CartDrawerContent = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const itemCount = result?.data?.cart?.totalQuantity ?? 0;
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const { pricesIncVat } = useChannelSettings();

  if (items.length === 0) {
    return (
      <ViewWrapper {...rest}>
        <CartHeader itemCount={itemCount} />
        <CartEmpty>{t('No items in cart.')}</CartEmpty>
      </ViewWrapper>
    );
  }

  return (
    <ViewWrapper {...rest}>
      <CartHeader itemCount={itemCount} />
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>
      <CartSummary>
        {discounts.length > 0 && (
          <div>
            <Heading>{t('Discounts')}</Heading>
            <Flex className="discount-value primary">
              <span>{t('Order value')}</span>
              <Price
                className="uppercase"
                price={result.data.cart.productPreviousTotal}
              />
            </Flex>
            {discounts.map((discount, index) => {
              return (
                <Flex key={index} className="secondary">
                  <span>{discount.name}</span>
                  <Price className="uppercase" price={discount.value} />
                </Flex>
              );
            })}
          </div>
        )}
        <div>
          {discounts.length > 0 && <Heading>{t('Total')}</Heading>}
          <Flex className="primary">
            <span className="uppercase">
              {pricesIncVat ? t('Total incl. VAT') : t('Total excl. VAT')}
            </span>
            <Price
              className="uppercase"
              price={result.data.cart.productTotal}
            />
          </Flex>

          <Flex className="secondary">
            <span>{pricesIncVat ? t('of which VAT') : t('+ VAT')}</span>
            <div className="uppercase">
              <Currency value={result.data.cart.productTotal.vat} />
            </div>
          </Flex>
        </div>
        <div>
          <Flex>
            <FreeShipping cartTotal={result.data.cart.productTotal} />
          </Flex>
        </div>
      </CartSummary>

      <Summary>
        {checkoutUrl && (
          <Checkout
            className="action"
            data-testid="checkout-button"
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: result.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}

        <DrawerTrigger preventOverflow={true} id="cart-drawer">
          {drawer => (
            <ContinueShopping className="secondary" onClick={drawer.hideTarget}>
              {t('Continue shopping')}
            </ContinueShopping>
          )}
        </DrawerTrigger>
      </Summary>
      <Summary>
        <FindifyRecommendations recIdString={'product-findify-rec-7'} />
      </Summary>
    </ViewWrapper>
  );
};

export default CartDrawerContent;
