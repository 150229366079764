import { css, cx } from 'linaria';
import React from 'react';
import { theme } from '../Theme';

const containerStyles = css`
  background: white;
  height: 100%;
  // clamp(MIN, VAL, MAX) resolves to max(MIN, min(VAL, MAX))
  width: max(392px, min(calc(3.5rem + 37vw), calc(100vw - 1440px - 3.5rem)));
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  z-index: 99999;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;

  ${theme.below.md} {
    width: 320px;
  }
`;

const Drawer = ({ right = false, isOpen = false, children }) => {
  const initialPosition = right
    ? {
        transform: `translateX(${isOpen ? `0px` : `100%`})`,
        right: 0
      }
    : {
        transform: `translateX(-${isOpen ? `0px` : `100%`})`,
        left: 0
      };

  return (
    <div
      className={cx(
        containerStyles,
        isOpen ? 'open' : 'closed',
        right ? 'right' : 'left'
      )}
      style={{
        ...initialPosition
      }}
    >
      {children}
    </div>
  );
};

export default Drawer;
