import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';

export const secondaryStyles = `
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.grey};
  color: ${theme.colors.black};

  :hover,
  :focus {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.black};
  }

  :disabled {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.disabled};
    color: ${theme.colors.grey};
    
    &:hover,
    &:focus {
      background-color: ${theme.colors.white};
    }
  }
`;

const actionStyles = `
  color: ${theme.colors.black};
  background-color: ${theme.colors.tretornYellow};

  :hover,
  :focus {
    background-color: ${theme.colors.hoverAddToCart};
  }

  :disabled {
    background-color: ${theme.colors.disabled};
    color: ${theme.colors.black};
  }
`;

export const smallStyles = `
  ${secondaryStyles};
  padding: 0.625rem 1rem;
  height: 34px;

  &:active {
    color: ${theme.colors.white};
    background-color: ${theme.colors.tretornBlue};
    border: 0;
  }
`;

export const underlineStyles = `
  background-color: transparent;
  color: black;
  border-bottom: 1px solid black;
  height: 28px;
  font-weight: 400;
`;

export const transparentStyles = `
  background-color: transparent;
  color: black;
  font-weight: 400;
  height: 28px;
  margin: 0;
  :hover {
    border-bottom: 1px solid black;
  }
`;

export const baseStyles = `
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.25rem;
  height: 48px;
  max-width: 100%;
  width: max-content;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${theme.colors.white};
  background-color: ${theme.colors.tretornBlue};
  border: 0;
  border-radius: 2px;
  transition: background-color 0.5s ease;

  :hover,
  :focus {
    background-color: ${theme.colors.hoverPrimaryButton};
  }
  
  &.disabled, :disabled {
    background-color: ${theme.colors.disabled};
    cursor: not-allowed;
    
    &:hover,
    &:focus {
      background-color: ${theme.colors.disabled};
    }
  }
  
  &.secondary {
    ${secondaryStyles};
  }

  &.action {
    ${actionStyles};
  }

  &.small {
    ${smallStyles};
  }
  
  span {
    margin-right: 12px;
  }
  &.underline {
    ${underlineStyles}
  }
  &.transparent {
    ${transparentStyles}
  }
`;

const Button = styled('button')`
  ${baseStyles};
`;

export const LinkButton = styled(Link)`
  ${baseStyles};
  text-decoration: none;
`;

export const ExternalLinkButton = styled('a')`
  ${baseStyles};
  text-decoration: none;
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  secondary,
  action,
  small,
  underline,
  transparent,
  ...props
}) =>
  loading ? (
    <Button
      {...props}
      className={cx(
        secondary && 'secondary',
        action && 'action',
        small && 'small',
        props.className
      )}
    >
      <span>{loadingText}</span>
      <StyledSpinner />
    </Button>
  ) : (
    <Button
      {...props}
      className={cx(
        secondary && 'secondary',
        action && 'action',
        small && 'small',
        underline && 'underline',
        transparent && 'transparent',
        props.className
      )}
    />
  );

export default ButtonWithLoading;
