import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import t from '@jetshop/intl';
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';

import { ReactComponent as PlusSVG } from '../../svg/plus.svg';
import { ReactComponent as MinusSVG } from '../../svg/minus.svg';

import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';

import { theme } from '../Theme';

const CartItemWrapper = styled('div')`
  display: grid;
  margin: 1rem 0;
  grid-template-areas:
    'image productdetails remove'
    'image amount amount';
  grid-template-columns: 92px auto auto;
  grid-gap: 1rem;
`;
const ImageWrapper = styled('div')`
  grid-area: image;
  display: flex;
  align-items: flex-start;
  margin: 0 0 1rem 0;
  height: 100%;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const ProductName = styled('div')`
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  h3 {
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
    color: ${theme.colors.black};
  }
`;

const ProductDetails = styled('div')`
  font-size: 0.75rem;
  letter-spacing: 0.01em;
`;

const LinePrice = styled(Price)`
  font-size: 0.75rem;
  letter-spacing: 0.05em;
  display: flex;
  flex-wrap: wrap;
  text-transform: uppercase;

  > div {
    margin-right: 0.5rem;

    &.new-price {
      color: ${theme.colors.discount};
    }
    &.old-price {
      color: ${theme.colors.text};
    }
  }
`;

const ItemDetails = styled('div')`
  grid-area: productdetails;
`;
const AdjustQty = styled('div')`
  grid-area: amount;
  display: flex;
  align-items: flex-end;
`;

const ProductIncDec = styled('div')`
  display: flex;
  > * {
    display: flex;
    align-items: center;
    flex: 1 1 0;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: ${theme.colors.offWhite};
    border-top: 1px solid ${theme.colors.lightGrey};
    border-bottom: 1px solid ${theme.colors.lightGrey};
  }
  span {
    font-size: 0.75rem;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.05);
  }
  button {
    background-color: transparent;
    border: 1px solid ${theme.colors.lightGrey};
    &:first-child {
      border-radius: 2px 0 0 2px;
    }
    &:last-child {
      border-radius: 0 2px 2px 0;
    }
    svg {
      width: 10.5px;
      height: 10.5px;
      path {
        fill: ${theme.colors.black};
      }
    }
  }
`;

const RemoveItem = styled('div')`
  grid-area: remove;
  font-size: 0.75rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  button {
    background: none;
    text-decoration: underline;
  }
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  return (
    <DrawerTrigger id="cart-drawer">
      {drawer => (
        <>
          <CartItemWrapper className={className}>
            <ImageWrapper>
              {item.product.images.length > 0 && (
                <Link
                  to={item.product.primaryRoute.path}
                  onClick={drawer.hideTarget}
                >
                  <Image
                    aspect="1:1"
                    sizes="5rem"
                    src={
                      hasVariantImage
                        ? variantImage.url
                        : item.product.images[0].url
                    }
                    alt={
                      hasVariantImage
                        ? variantImage.alt
                        : item.product.images[0].alt
                    }
                    quality={80}
                  />
                </Link>
              )}
            </ImageWrapper>

            <ItemDetails>
              <ProductName onClick={drawer.hideTarget}>
                <h3 data-testid="item-name">
                  <Link
                    to={item.product.primaryRoute.path}
                    onClick={drawer.hideTarget}
                  >
                    {item.product.name}
                  </Link>
                </h3>
              </ProductName>
              <ProductDetails>
                {isVariant && (
                  <ul>
                    {variantNamesValues.map((variantOptionNameValue, index) => (
                      <li className="attribute" key={index}>
                        {variantOptionNameValue}
                      </li>
                    ))}
                  </ul>
                )}
                {item.configurations.length > 0 && (
                  <ul>
                    {item.configurations.map(({ option: { name } }, index) => (
                      <li className="attribute" key={index}>
                        {name}
                      </li>
                    ))}
                  </ul>
                )}
              </ProductDetails>
              <LinePrice
                data-testid="item-price"
                price={item.total}
                previousPrice={item.previousTotal}
              />
            </ItemDetails>

            <RemoveItem>
              <button
                onClick={() =>
                  removeFromCart({ itemId: item.id, product: item.product })
                }
                data-testid="remove-from-cart"
              >
                {t('Remove')}
              </button>
            </RemoveItem>

            <AdjustQty>
              <ProductIncDec>
                <button
                  data-testid="decrement-quantity"
                  disabled={item.quantity === 1}
                  onClick={() =>
                    item.quantity !== 1 &&
                    decrementQuantity({ itemId: item.id })
                  }
                >
                  <MinusSVG />
                </button>
                <span data-testid="item-quantity">{item.quantity}</span>
                <button
                  data-testid="increment-quantity"
                  onClick={() => incrementQuantity({ itemId: item.id })}
                >
                  <PlusSVG />
                </button>
              </ProductIncDec>
            </AdjustQty>
          </CartItemWrapper>
        </>
      )}
    </DrawerTrigger>
  );
};

export default CartItem;
