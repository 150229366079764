import React from 'react';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import { ReactComponent as FacebookLogo } from '../../../svg/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../../svg/instagram.svg';
import { H5, SmallTextMedium } from '../../ui/Typography';
import DynamicLink from '../../ui/Link';

const Wrapper = styled('div')`
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
  }

  li {
    margin: 0;
  }

  li + li {
    margin-left: 1.375rem;
  }

  a.social-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const LogoWrapper = styled('span')`
  background: transparent;
  width: 37px;
  height: 37px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;

    path,
    circle {
      transition: fill 0.3s ease;
    }

    &:hover {
      path {
        fill: #d4d5c3;
      }
      circle {
        fill: #1a1a1a;
      }
    }
  }
`;

const SocialLinks = ({ data }) => (
  <Wrapper>
    <Above breakpoint="md">
      {matches =>
        matches ? (
          <span className={SmallTextMedium}>{data.title}</span>
        ) : (
          <H5>{data.title}</H5>
        )
      }
    </Above>
    <ul>
      {data.social_media.map(item => (
        <li key={item._uid}>
          <DynamicLink
            classnames="social-links"
            aria-label={item.text}
            link={item}
          >
            <LogoWrapper>
              {item.text.toLowerCase() === 'instagram' ? (
                <InstagramLogo />
              ) : item.text.toLowerCase() === 'facebook' ? (
                <FacebookLogo />
              ) : null}
            </LogoWrapper>
          </DynamicLink>
        </li>
      ))}
    </ul>
  </Wrapper>
);

export default SocialLinks;
