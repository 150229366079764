import React, { useState, useContext, useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as Cross } from '../../../svg/close.svg';
import { ReactComponent as Caret } from '../../../svg/Caret.svg';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { baseStyles } from '../../ui/Button';
import { dropdownStyles } from '../../ui/Dropdown';
import { withCookies } from 'react-cookie';
import { countryList } from './staticData';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Wrapper = styled('section')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    width: 23.5rem;
    max-width: 100%;
    background-color: white;
    padding: 3rem 1rem 2rem;
    position: relative;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;

    padding: 1rem;
    background: none;
    display: block;
    svg {
      width: 0.875rem;
      height: 0.875rem;
      display: block;
      path {
        stroke: ${theme.colors.black};
      }
    }
  }

  h1 {
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 1.25;
    color: ${theme.colors.black};
    text-align: center;
    margin: 0 auto 2.5rem;
    max-width: 200px;
  }

  .dropdown {
    margin-bottom: 2.5rem;

    ${dropdownStyles};
    [data-flight-dropdown-button] {
      border: 1px solid #b2b2b2;
      font-weight: 400;
      &[aria-expanded='true'],
      &:focus {
        border-color: #b2b2b2;
      }
    }
    [data-flight-dropdown-items] {
      max-height: 38vh;
      overflow: auto;
      border-top: 1px solid;
      border-color: ${theme.colors.black};
    }

    [data-flight-dropdown-item] {
      background-color: white;
      color: ${theme.colors.black};
      &.active {
        font-weight: 600;
      }
      :hover,
      :focus {
        background-color: ${theme.colors.offWhite};
        color: ${theme.colors.black};
        font-weight: 600;
      }
    }
  }

  .submit {
    ${baseStyles};
    margin: 0 auto;
    text-decoration: none;
  }
`;

function getGeoCountry() {
  if (typeof window === 'undefined') return {};

  const geoLocationChecked = true;

  const geoCountryCode = window.__GEOIP_COUNTRY__?.toUpperCase();
  const geoCountry = countryList.find(country =>
    country.codes.includes(geoCountryCode)
  );

  return { geoCountry, geoLocationChecked, geoCountryCode };
}

const MarketSelector = ({ callback = () => {}, cookies }) => {
  const { geoCountry, geoCountryCode, geoLocationChecked } = getGeoCountry();

  const [selectedCountry, setSelectedCountry] = useState(geoCountry);
  const { selectedChannel } = useContext(ChannelContext);
  const geoCountryCodeCookie = cookies.cookies['geoCountryCode'];
  const isIt =
    selectedChannel.country.code === 'SE' &&
    geoLocationChecked &&
    geoCountryCode !== 'SE' &&
    geoCountryCodeCookie !== 'SE';
  const [isVisible, setIsVisible] = useState(isIt);

  useEffect(() => {
    if (!isVisible) callback();
  }, [isVisible, callback]);

  function submitCallback({ hide = false }) {
    if (selectedCountry?.codes[0])
      cookies.set('geoCountryCode', selectedCountry.codes[0]);
    if (hide) hidePopup();
  }
  function hidePopup() {
    callback();
    setIsVisible(false);
  }

  if (!isVisible) return null;

  return (
    <Wrapper>
      <div className="inner">
        <button className="close" onClick={hidePopup}>
          <Cross />
        </button>
        <h1>Select Your Tretorn Region</h1>
        <div className="dropdown">
          <DropdownMenu>
            <DropdownMenuButton>
              {selectedCountry ? selectedCountry.name : 'Select country'}
              <Caret className="carot" />
            </DropdownMenuButton>
            <DropdownMenuItems style={{ zIndex: 9999 }}>
              {countryList.map(country => (
                <DropdownMenuItem
                  key={country.name}
                  className={
                    country.name === selectedCountry?.name ? 'active' : ''
                  }
                  closeOnSelect={true}
                  onSelect={() => {
                    setSelectedCountry(country);
                  }}
                >
                  {country.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuItems>
          </DropdownMenu>
        </div>
        <ContinueButton
          selectedCountry={selectedCountry}
          submitCallback={submitCallback}
        >
          Continue
        </ContinueButton>
      </div>
    </Wrapper>
  );
};

const ContinueButton = ({ selectedCountry, submitCallback, children }) => {
  if (!selectedCountry)
    return (
      <button disabled className="submit disabled" onClick={() => {}}>
        {children}
      </button>
    );

  return selectedCountry.url ? (
    <a className="submit" onClick={submitCallback} href={selectedCountry.url}>
      {children}
    </a>
  ) : (
    <button className="submit" onClick={() => submitCallback({ hide: true })}>
      {children}
    </button>
  );
};

export default withCookies(MarketSelector);
