import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { Above } from '@jetshop/ui/Breakpoints';
import { ReactComponent as Minus } from '../../../svg/minus.svg';
import { ReactComponent as Plus } from '../../../svg/plus.svg';
import { theme } from '../../Theme';
import { Heading5 } from '../../ui/Typography';
import DynamicLink from '../../ui/Link';

const LinkSection = css`
  ${theme.above.md} {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1rem;
  }

  .link-grid {
    grid-column: span 2;
  }
`;

const Heading = styled('strong')`
  ${Heading5};
  display: block;
  margin: 0;
`;

const PageLink = css`
  margin: 0.25rem 0;
  a {
    text-decoration: none;
    color: ${theme.colors.text};
    font-size: 0.875rem;
    :hover {
      text-decoration: underline;
      color: ${theme.colors.black};
    }
  }

  ${theme.below.md} {
    font-size: 0.875rem;
    color: ${theme.colors.black};
  }
`;

const AccordionWrapper = css`
  margin: 0 -1rem;
  border-top: 1px solid ${theme.colors.darkTretornBeige};

  .accordion-content {
    li {
      margin: 0.5rem 0;
    }
  }
`;

const AccordionItem = css`
  padding: 20px 1rem;
  border-bottom: 1px solid ${theme.colors.darkTretornBeige};

  div.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;

    span {
      font-weight: 600;
      color: ${theme.colors.black};
    }
  }
`;

const AccordionIndicator = css`
  display: flex;
  width: 12px;
  height: 12px;
  svg {
    width: 12px;
    height: 12px;
  }
`;

const FooterLinks = ({ sections }) => {
  if (!sections) return null;
  return (
    <div className={LinkSection}>
      <Above breakpoint="md">
        {matches =>
          matches ? (
            sections.map(section => (
              <ul key={section._uid} className="link-grid">
                <li>
                  <Heading>{section.title}</Heading>
                </li>
                {section.link.map(link => (
                  <li key={link._uid} className={PageLink}>
                    <DynamicLink link={link}>{link.text}</DynamicLink>
                  </li>
                ))}
              </ul>
            ))
          ) : (
            <Accordion>
              {({ openIndexes, handleClick, AccordionContent }) => (
                <ul role="tablist" className={AccordionWrapper}>
                  {sections.map((section, index) => {
                    let isOpen = openIndexes.includes(index);
                    return (
                      <li
                        key={section._uid}
                        className={cx(AccordionItem, isOpen && 'active')}
                        aria-expanded={isOpen ? true : false}
                        onClick={() => handleClick(index)}
                        onKeyPress={() => handleClick(index)}
                        tabIndex="0"
                      >
                        <div className="title">
                          <span>{section.title}</span>
                          <span className={AccordionIndicator}>
                            {isOpen ? <Minus /> : <Plus />}
                          </span>
                        </div>
                        <AccordionContent isOpen={isOpen}>
                          <ul className="accordion-content" role="tabpanel">
                            {section.link.map(link => (
                              <li key={link._uid} className={PageLink}>
                                <DynamicLink link={link}>
                                  {link.text}
                                </DynamicLink>
                              </li>
                            ))}
                          </ul>
                        </AccordionContent>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Accordion>
          )
        }
      </Above>
    </div>
  );
};

export default FooterLinks;
