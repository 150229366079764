import React, { useState, useContext } from 'react';
import { styled } from 'linaria/react';

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  z-index: 5;

  &.active {
    display: block;
  }
`;

const AddedToCartContext = React.createContext([]);
const AddedToCartUpdateContext = React.createContext();

export const useAddedToCart = _ => useContext(AddedToCartContext);
export const useAddedToCartUpdate = _ => useContext(AddedToCartUpdateContext);

export const AddedToCartNotificationProvider = ({
  exitDuration,
  overlay,
  children
}) => {
  const [modal, setModal] = useState([]);
  const push = (component, settings) => {
    const props = {
      Component: component,
      state: 'active',
      id: Date.now() + Math.random(),
      ...settings
    };
    if (modal.filter(modal => modal.id == props.id).length > 0) return;
    setModal(modals => [...modals, props]);
  };
  const updateState = (ids, state) => {
    setModal(modalStack => {
      const copy = [...modalStack];
      ids.forEach(id => {
        const indexOf = modalStack.findIndex(entry => entry.id == id);
        if (indexOf > -1) {
          copy[indexOf].state = state;
        }
      });
      return copy;
    });
  };
  const clear = (id, delay) => {
    const updateModal = _ =>
      setModal(stack => stack.filter(item => item.id !== id));

    if (delay > 0) {
      updateState([id], 'exiting');
      setTimeout(() => {
        updateModal();
      }, delay);
    } else {
      updateModal();
    }
  };

  const clearAll = delay => {
    const ids = modal
      .filter(entry => entry.state == 'active')
      .map(entry => entry.id);

    updateState(ids, 'exiting');

    if (delay > 0) {
      setTimeout(_ => setModal([]), delay);
    } else {
      setModal([]);
    }
  };

  return (
    <AddedToCartUpdateContext.Provider value={{ push, clear, clearAll }}>
      <AddedToCartContext.Provider value={modal}>
        {children}
        {overlay && (
          <Overlay
            className={
              modal.filter(entry => entry.state == 'active').length > 0 &&
              'active'
            }
            onClick={_ => clearAll(exitDuration)}
          />
        )}
      </AddedToCartContext.Provider>
    </AddedToCartUpdateContext.Provider>
  );
};
