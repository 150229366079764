export const countryList = [
  { codes: ['SE'], name: 'Sweden' },
  { codes: ['DK'], url: 'https://www.tretorn.dk/', name: 'Denmark' },
  { codes: ['NO'], url: 'https://www.tretorn.no/', name: 'Norway' },
  { codes: ['FI'], url: 'https://www.tretorn.fi/', name: 'Finnland' },
  { codes: ['DE'], url: 'https://de.tretorn.com/', name: 'Germany' },
  { codes: ['GB'], url: 'https://gb.tretorn.com/', name: 'United Kingdom' },
  { codes: ['NL'], url: 'https://nl.tretorn.com/', name: 'Netherlands' },
  { codes: ['ES'], url: 'https://es.tretorn.com/', name: 'Spain' },
  { codes: ['FR'], url: 'https://www.tretorn.fr/', name: 'France' },
  { codes: ['IT'], url: 'https://it.tretorn.com/', name: 'Italy' },
  { codes: ['US'], url: 'https://us.tretorn.com/', name: 'United States' },
  {
    codes: [
      'EU',
      'BE',
      'BG',
      'CZ',
      'EE',
      'GR',
      'IE',
      'HR',
      'LV',
      'LT',
      'LU',
      'MT',
      'AT',
      'PL',
      'PT',
      'RO',
      'SK',
      'SI',
      'HU'
    ],
    url: 'https://www.tretorn.eu/',
    name: 'Europe'
  }
];
