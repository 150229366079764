import { styled } from 'linaria/react';
import React from 'react';

import t from '@jetshop/intl';
import { Price } from '@jetshop/ui/Price';
import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';

const DeliveryRequirements = styled('div')`
  text-transform: initial;
  .price {
    text-transform: uppercase;
    display: inline;
  }
`;

function FreeShipping({ cartTotal, ...rest }) {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });

  if (!freeShippingConfigured) return null;

  if (!hasMetLimit) {
    return (
      <div>
        <span className="primary">{t('Delivery')}</span>
        <DeliveryRequirements className="secondary">
          {t.rich(`{price} more required to qualify for free shipping!`, {
            price: <Price className="price" key="price" price={untilLimit} />
          })}
        </DeliveryRequirements>
      </div>
    );
  }

  return (
    <>
      <span className="primary">{t('Delivery')}</span>
      <div className="primary">{t('Free for your order')}</div>
    </>
  );
}

export default FreeShipping;
