import { useEffect, useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import StoryblokLanguageCodesQuery from './StoryblokLanguageCodesQuery.gql';
import StoryblokRouteQuery from './queries/StoryblokRouteQuery.gql';
import { StoryblokDraftContext } from './StoryblokDraftContext';
import { defaultLocale } from '../../shop.config';
import { useAppSettingsStore } from '../../appSettingsStore';

export const defaultStoryblokLanguage = defaultLocale;

export const isInsideStoryblokEditorMode = () =>
  typeof window !== 'undefined' &&
  window.location.search.includes('_storyblok');

export function useStoryblokBridge(originalStory, preview, options = {}) {
  const [story, setStory] = useState(originalStory);
  // adds the events for updating the visual editor
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#initializing-the-storyblok-js-bridge
  function initEventListeners() {
    const { StoryblokBridge } = window;
    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge({
        ...options
      });

      storyblokInstance.on(['change', 'published'], () => location.reload());

      storyblokInstance.on('input', event => {
        if (event.story.content._uid === story._uid) {
          setStory(event.story.content);
        }
      });
    }
  }
  const setShippingAndReturns = useAppSettingsStore(
    state => state.setShippingAndReturns
  );
  const setFreeShippingReturns = useAppSettingsStore(
    state => state.setFreeShippingReturns
  );
  if (story?.content?.component === 'appSettings') {
    setShippingAndReturns(story?.content?.shippingAndReturns);
    setFreeShippingReturns(story?.content?.freeShippingReturns);
  }
  // appends the bridge script tag to our document
  // see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
  function addBridge(callback) {
    // check if the script is already present
    const existingScript = document.getElementById('storyblokBridge');
    if (!existingScript && isInsideStoryblokEditorMode()) {
      const script = document.createElement('script');
      script.src = '//app.storyblok.com/f/storyblok-v2-latest.js';
      script.id = 'storyblokBridge';
      document.body.appendChild(script);
      script.onload = () => {
        // once the scrip is loaded, init the event listeners
        callback();
      };
    } else {
      callback();
    }
  }

  useEffect(() => {
    if (preview) {
      addBridge(initEventListeners);
    }
  }, []);

  return story;
}

// usage:
// useStoryblokInternationalizedPathname('/customer-service/vanliga-fragor');
export const useStoryblokInternationalizedPathname = pathname => {
  const isDraft = useContext(StoryblokDraftContext);
  let language = useStoryblokLanguage() || defaultStoryblokLanguage;

  if (isDraft?.language) {
    language =
      isDraft.language == 'default'
        ? defaultStoryblokLanguage
        : isDraft.language;
  }

  const parts = pathname.split('/').filter(part => part !== '');
  pathname = parts.join('/');
  if (language === parts[0]) {
    pathname = pathname.substring(language.length + 1);
  }
  if (defaultStoryblokLanguage === language) return pathname;
  return `/${language}/${pathname}`;
};

// we should get the selected language somehow
export const useStoryblokLanguage = () => {
  const { data, loading, error } = useQuery(StoryblokLanguageCodesQuery);
  const { selectedChannel } = useContext(ChannelContext);
  if (loading || error) return;
  const availableLanguages = [...(data?.StoryblokSpace?.languageCodes || [])];

  const language = selectedChannel?.language?.culture
    ?.toLowerCase()
    .split('-')[0];
  const countryCode = selectedChannel?.country?.code?.toLowerCase();
  const culture = `${language}-${countryCode}`;
  if (availableLanguages.includes(culture)) return culture;
  if (availableLanguages.includes(language)) return language;
};

// Check if storyblok links are internal (linktype story)
// or external (linktype url) and return proper link
export const handleLink = link => {
  if (!link) return '';

  if (link.linktype === 'email') {
    return `mailto:${link.email}`;
  }

  let _link;

  if (typeof link === 'string') {
    _link = link;
  } else {
    _link =
      link.linktype === 'story' || link.linktype === 'url'
        ? link.cached_url
        : link.url;
  }
  if (!_link.startsWith('http') && !_link.startsWith('/')) {
    _link = `/${_link}`;
  }
  return _link;
};
export const useStoryblokAppsettings = () => {
  const { data } = useQuery(StoryblokRouteQuery, {
    variables: { path: useStoryblokInternationalizedPathname('appsettings') }
  });
  const content = data?.StoryblokContentNode?.content;
  const setShippingAndReturns = useAppSettingsStore(
    state => state.setShippingAndReturns
  );
  const setFreeShippingReturns = useAppSettingsStore(
    state => state.setFreeShippingReturns
  );
  const setPdpSpecExcludeList = useAppSettingsStore(
    state => state.setPdpSpecExcludeList
  );
  const exclusionArr = content?.specificationExcludeList
    ?.replace(/[ \t\r\n]+/g, '')
    ?.split(',');
  setPdpSpecExcludeList(exclusionArr || []);
  setShippingAndReturns(content?.shippingAndReturns);
  setFreeShippingReturns(content?.freeShippingReturns);
};
