import React, { Fragment } from 'react';
import { styled } from 'linaria/react';

import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { useIntl } from '@jetshop/intl';

import { ReactComponent as CartIcon } from '../../svg/cart.svg';

import cartQuery from './CartQuery.gql';

import { theme } from '../Theme';
import { FindifyCartTracker } from '../Findify';

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
`;

const CartButtonWrapper = styled('div')`
  position: relative;
  width: 24px;

  svg {
    width: 24px;
  }
`;

const Badge = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -2px;
  width: 21px;
  height: 21px;
  font-size: 0.5625rem;
  font-weight: 600;
  pointer-events: none;
  background-color: ${theme.colors.tretornYellow};
  border: 2px solid white;
  border-radius: 50%;
  overflow: hidden;
  letter-spacing: -0.05em;

  span {
    position: relative;
    top: 1px;
  }
`;

function CartButton({ target, itemsInCart, className, children }) {
  const intl = useIntl();
  return (
    <CartButtonWrapper>
      <Button
        aria-label={intl('Cart')}
        className={className}
        data-testid="cart-button"
        onClick={target.isOpen ? target.hideTarget : target.showTarget}
      >
        <CartIcon />
        {children}
      </Button>
      {itemsInCart > 0 && (
        <Badge>
          <span>{itemsInCart}</span>
        </Badge>
      )}
    </CartButtonWrapper>
  );
}

function CartButtonFlyout({ className, children }) {
  return (
    <Fragment>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = result?.data?.cart?.totalQuantity || 0;
          return (
            <>
              <DrawerTrigger preventOverflow={true} id="cart-drawer">
                {drawer => (
                  <CartButton
                    className={className}
                    target={drawer}
                    itemsInCart={itemsInCart}
                  >
                    {children}
                  </CartButton>
                )}
              </DrawerTrigger>
              <FindifyCartTracker cartData={result?.data?.cart} />
            </>
          );
        }}
      </CartProvider>
    </Fragment>
  );
}

export default CartButtonFlyout;
