import React from 'react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import { handleLink } from '../Storyblok/storyblok';

const linkStyles = css`
  font: inherit;
  color: currentColor;
`;

const DynamicLink = ({ link, classnames, children, ...rest }) => {
  if (link?.link?.linktype === 'story') {
    return (
      <div>
        <Link
          to={handleLink(link.link)}
          className={cx(linkStyles, classnames)}
          {...rest}
        >
          {children}
        </Link>
      </div>
    );
  }
  return (
    <a
      href={handleLink(link.link)}
      className={cx(linkStyles, classnames)}
      {...rest}
    >
      {children}
    </a>
  );
};

export default DynamicLink;
