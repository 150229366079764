import React, { useContext } from 'react';

import StartPage from '../StartPage/StartPage.loadable';
import JournalPage from './ui/stories/JournalPage/JournalPage.loadable';
import JournalOverview from './ui/stories/JournalPage/JournalOverview.loadable';
import StoryblokContentPage from './ui/stories/ContentPage/ContentPage.loadable';
import ImageLayout from './ui/bloks/ImageLayout.loadable';
import ArticleHero from './ui/bloks/ArticleHero.loadable';
import TextBlock from './ui/bloks/TextBlock.loadable';
import QuoteBlock from './ui/bloks/QuoteBlock.loadable';
import VideoPlayer from './ui/bloks/VideoPlayer.loadable';
import ProductHighlight from './ui/bloks/ProductHighlight.loadable';
import ShopTheLook from './ui/bloks/ShopTheLook.loadable';
import TextAndImageLayout from './ui/bloks/TextAndImageLayout.loadable';

import { useStoryblokBridge } from './storyblok';
import SbEditable from 'storyblok-react';
import { StoryblokDraftContext } from './StoryblokDraftContext';
import { Header } from '../Layout/Header/Header';
import { Footer } from '../Layout/Footer/Footer';
import Hreflang, {
  useStoryblokTranslatedSlugsToAlternateRoutes
} from '../Hreflang';

const FallbackComponent = ({ component }) => (
  <div>The component "{component}" has not been created yet.</div>
);

const components = {
  // pages
  start_page: StartPage,
  content_page: StoryblokContentPage,
  journal_page: JournalPage,
  journal_overview: JournalOverview,
  image_layout: ImageLayout,
  article_hero: ArticleHero,
  text_block: TextBlock,
  quote_block: QuoteBlock,
  vieo_player: VideoPlayer,
  product_highlight: ProductHighlight,
  shop_the_look: ShopTheLook,
  text_and_image_layout: TextAndImageLayout,
  header: Header,
  footer: Footer,
  // fallback
  __fallback: FallbackComponent
};

const DynamicStoryblokStory = ({
  story,
  component,
  translatedSlugs,
  defaultLanguageSlug,
  ...rest
}) => {
  const alternateRoutes = useStoryblokTranslatedSlugsToAlternateRoutes({
    translatedSlugs,
    defaultLanguageSlug
  });
  const isDraft = useContext(StoryblokDraftContext);
  let Component = component;
  if (!Component) {
    const type = story?.component;
    Component =
      components[type] ?? (isDraft ? components['__fallback'] : undefined);
  }
  if (!story || !Component) return null;

  const bridgeStory = useStoryblokBridge(story, isDraft, {
    resolveRelations: ['articles', 'journal.articles']
  });
  if (isDraft) {
    story = bridgeStory;
  }
  return (
    <>
      <Hreflang renderAll alternateRoutes={alternateRoutes} />
      <SbEditable content={story}>
        <Component {...story} {...rest} />
      </SbEditable>
    </>
  );
};

export default DynamicStoryblokStory;
