import React from 'react';
import loadable from '@loadable/component';
import LoadingPage from '../LoadingPage';

export const CategoryPage = loadable(() => import('./CategoryPage'), {
  fallback: <LoadingPage />
});

export const StandardCategoryPage = loadable(
  () => import('./StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const WindowedCategoryPage = loadable(
  () => import('./WindowedCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);
