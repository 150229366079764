import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { ReactComponent as ChevronSVG } from '../../../../svg/chevron.svg';
import { ReactComponent as CloseSVG } from '../../../../svg/close.svg';
const StyledCloseButton = styled('button')`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  width: 20px;
  height: 20px;
  line-height: 0;
  overflow: hidden;
  z-index: 999;
  transform: translateX(25%);

  span {
    display: none;
  }
  svg {
    width: 14px;
  }
`;

const CloseButton = ({ close }) => (
  <StyledCloseButton onClick={close}>
    <span>{t('Close')}</span>
    <CloseSVG />
  </StyledCloseButton>
);

const StyledBackButton = styled('button')`
    display: flex;
    align-items: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    background: none;

    svg {
      position: relative;
      top: -1px;
      margin-right: .5rem;
      transform: rotate(90deg);
    }
  }
`;

const GoBack = ({ children, pop }) => {
  return (
    <StyledBackButton onClick={pop}>
      <ChevronSVG /> {children || t('Go back')}
    </StyledBackButton>
  );
};

const PanelWrapper = ({ drawer, pop, push, back, children }) => {
  return (
    <div className="panel">
      <CloseButton close={drawer.hideTarget} />
      {back && <GoBack pop={pop} />}
      {children}
    </div>
  );
};

export default PanelWrapper;
