import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import MaxWidth from '../MaxWidth';
import ChannelSelector from './ChannelSelector/ChannelSelector';
import CountDown from '../../ui/CountDown';
import { theme } from '../../Theme';
import Link from '../../ui/Link';
import { Helmet } from 'react-helmet-async';

const Wrapper = styled(MaxWidth)`
  align-self: stretch;
`;

const TopBarContainer = styled('div')`
  position: relative;
  z-index: 4;
  display: flex;
  align-items: center;
  align-self: stretch;
  background-color: ${({ campaign }) =>
    campaign ? theme.colors.tretornBlue : theme.colors.tretornGrey};
  color: ${({ campaign }) =>
    campaign ? theme.colors.white : theme.colors.tretornBlue};
  font-size: 0.75rem;
  min-height: 30px;
  font-weight: 600;
  /* Extra verbose to override MaxWidth p styling */
  p {
    font-size: 0.75rem;
  }

  ${theme.below.md} {
    z-index: 2;
    min-height: 28px;
  }
`;
const TopBarTitle = styled.h4`
  text-transform: uppercase;
  letter-spacing: 0.15em;
`;
const Inner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: ${({ withChannelSelector }) =>
    withChannelSelector ? 'space-between' : 'center'};
  align-self: stretch;
  height: 100%;

  ${theme.below.md} {
    min-height: 28px;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
    padding: 8px 0;
  }
`;
const TextContent = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`;
const StyledCountDown = styled(CountDown)`
  font-weight: bold;
  letter-spacing: 0.15em;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const RightTopBar = styled('div')`
  display: flex;
  gap: 8px;
  height: 18px;
  align-items: center;
`;

const TestFreaksSeller = styled('div')`
  height: 18px;
`;

const TopBar = ({
  campaign,
  title,
  text,
  link,
  end_date: endDate,
  withChannelSelector
}) => {
  const content = (
    <TextContent>
      {title ? <TopBarTitle>{title}</TopBarTitle> : null}
      {text ? <p>{text}</p> : null}
      {endDate ? <StyledCountDown date={endDate} /> : null}
    </TextContent>
  );
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
          testFreaks = window.testFreaks || []; 
          testFreaks.push(["load", ["seller"]]);
        `}
        </script>
      </Helmet>
      <TopBarContainer campaign={campaign}>
        <Wrapper>
          <Inner withChannelSelector={withChannelSelector}>
            {link ? (
              <StyledLink link={{ link }}>{content}</StyledLink>
            ) : (
              content
            )}
            <RightTopBar>
              {withChannelSelector ? (
                <Above breakpoint="md">
                  <ChannelSelector />
                </Above>
              ) : null}
              <TestFreaksSeller id="testfreaks-seller" />
            </RightTopBar>
          </Inner>
        </Wrapper>
      </TopBarContainer>
    </>
  );
};

export default TopBar;
