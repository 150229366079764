import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from '@jetshop/intl';

import MaxWidth from '../MaxWidth';
import TopBar from './TopBar';

import CartButton from '../../Cart/CartButton';
import SearchBar from './DesktopSearchBar';

import { theme } from '../../Theme';
import { handleLink } from '../../Storyblok/storyblok';
import { H4 } from '../../ui/Typography';
import { LinkButton } from '../../ui/Button';
import Image from '../../Storyblok/ui/components/Image';
import AddedToCartWrapper from '../../Cart/AddedToCartNotification/AddedToCartNotificationWrapper';

const Branding = styled('img')`
  height: auto;
`;

const Header = styled('header')`
  position: sticky;
  top: 0;
  z-index: 5;
`;

const HeaderWrapper = styled('div')`
  position: relative;
  background: white;
  z-index: 3;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${theme.colors.lightGrey};
  }
`;

const HomeLink = styled(Link)`
  ${theme.above.sm} {
    margin-right: 2.25rem;
  }
  ${theme.above.md} {
    margin-right: 3rem;
  }
`;

const Inner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
  > div {
    display: flex;
    align-items: center;
    align-self: stretch;
  }
  > div.cart-search {
    justify-content: flex-end;
    width: 100%;
  }
`;

const Navigation = styled('nav')`
  position: relative;
  text-transform: uppercase;
  height: 100%;
  font-size: 0.875rem;
  margin-right: 1.625rem;
  ul {
    display: flex;
    height: 100%;
    li:last-child {
      margin: 0;
    }
    li {
      font-size: inherit;
      margin-right: 2.25rem;
      height: 100%;
      a {
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        font-size: inherit;
        font-weight: 600;
        text-decoration: none;
        color: ${theme.colors.black};
        white-space: nowrap;
        letter-spacing: 0.08em;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0;
          height: 2px;
          transform: translateX(-50%);
          opacity: 0;
          background-color: ${theme.colors.black};
          transition: width 0.3s, opacity 0.2s;
        }
        &:not(.active):hover::before {
          width: 100%;
          opacity: 1;
        }
        &.active::before {
          width: 100%;
          opacity: 1;
          background-color: ${theme.colors.black};
        }
      }
    }

    ${theme.below.lg} {
      display: ${({ searchOpen }) => (searchOpen ? 'none' : 'flex')};
    }
  }
`;

const SubNavigation = styled('div')`
  position: absolute;
  bottom: 0;
  padding: 3rem 0 0;
  background: white;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  transform: translateY(0%);
  transition: transform 0.4s;

  &.hidden {
    display: none;
  }

  &.active {
    z-index: 2;
    /* on some instances in chrome there will be a subpixel and give the impression of double top border. so use 99.9% instead of 100% */
    transform: translateY(99.9%);
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  }

  div.grid {
    display: grid;
    grid-template-columns: 60% auto;
    width: 100%;
    height: 100%;
    .categories {
      display: flex;
      width: 100%;
      height: 100%;
      max-height: 504px;
      > ul > li {
        width: 200px;
        margin: 0 0 2.5rem;
        a {
          color: ${theme.colors.text};
          text-decoration: none;
          &:hover {
            color: ${theme.colors.black};
            text-decoration: underline;
          }
        }
        > a {
          color: ${theme.colors.black};
          font-weight: 700;
          font-size: 1.125rem;
          letter-spacing: 0.03em;
          text-transform: uppercase;
        }

        li {
          margin: 0.25rem 0;
          line-height: 1.58;
          a {
            font-weight: 400;
            font-size: 0.875rem;
          }
        }
      }
      ul.sub-categories {
        display: flex;
        align-content: flex-start;
        flex-flow: column wrap;
        height: 100%;
        width: 100%;
      }
    }
    ul.featured-categories {
      display: flex;
      flex-direction: column;
    }
    .featured-image {
      display: flex;
      align-items: flex-start;
      margin: 0 0 3rem;
      width: 100%;
      padding-left: 2.5rem;
      overflow: hidden;
      .text {
        margin: 1rem 0;
        text-align: center;
      }
      [data-storyblok-image-container] {
        width: 100%;
      }
      [data-storyblok-image-children] {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const MaxWidthWrapper = styled(MaxWidth)`
  position: relative;
  z-index: 1;
`;

const MaxWidthSubNav = styled(MaxWidth)`
  display: flex;
`;

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  pointer-events: none;
  transition: background 0.4s;

  &.active {
    background: rgba(0, 0, 0, 0.2);
    pointer-events: all;
  }
`;

const SubCategory = ({ className, resetActiveCategories, ...props }) => {
  if (!props) return null;
  return (
    <li className={className}>
      <Link to={handleLink(props.link)} onClick={resetActiveCategories}>
        {props.title}
      </Link>
      {props.nested_links?.length > 0 && (
        <ul>
          {props.nested_links.map(({ _uid, link, text }) => (
            <li key={_uid}>
              <Link to={handleLink(link)} onClick={resetActiveCategories}>
                {text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const SubNavigationSection = ({
  className,
  resetActiveCategories,
  ...props
}) => {
  const [firstLoad, setFirstLoad] = useState();

  useEffect(() => {
    // workaround to push this call to the end of the call-stack
    // otherwise, sometimes, the dom isn't ready and there is a race condition so the first hover would be instant
    // looks like the call order is updated in React version 17.0.2
    // https://stackoverflow.com/questions/59269330/why-useeffect-hook-runs-before-the-dom-is-loaded
    setTimeout(() => setFirstLoad(true), 0);
  }, []);

  const featuredCategories = props?.featured_subcategories;
  const subCategories = props?.subcategories;
  const featuredImage = props?.featured_image?.[0]?.image;
  if (featuredCategories?.length < 1 && subCategories?.length < 1) return null;

  return (
    <SubNavigation
      className={cx(className, firstLoad && props.active && 'active')}
      onMouseEnter={props.onEnter}
      onMouseLeave={props.onLeave}
    >
      <MaxWidthSubNav>
        <div className="grid">
          <div className="categories">
            {featuredCategories?.length > 0 && (
              <ul className="featured-categories">
                {featuredCategories.map((component, i) => (
                  <SubCategory
                    resetActiveCategories={resetActiveCategories}
                    {...component}
                    key={component._uid}
                  />
                ))}
              </ul>
            )}

            {subCategories?.length > 0 && (
              <ul className="sub-categories">
                {subCategories.map(component => (
                  <SubCategory
                    resetActiveCategories={resetActiveCategories}
                    key={component._uid}
                    {...component}
                  />
                ))}
              </ul>
            )}
          </div>
          {featuredImage && (
            <div className="featured-image">
              <Image
                aspect="544:370"
                width={544}
                cover={true}
                src={featuredImage.filename}
              >
                <H4 className="overlay text-shadow text">
                  {props?.featured_image[0]?.title}
                </H4>
                {props?.featured_image[0]?.button?.map(button => (
                  <LinkButton
                    to={handleLink(button.button_link)}
                    key={button._uid}
                    onClick={resetActiveCategories}
                  >
                    {button.button_text}
                  </LinkButton>
                ))}
              </Image>
            </div>
          )}
        </div>
      </MaxWidthSubNav>
    </SubNavigation>
  );
};

const Desktop = storyblok => {
  const intl = useIntl();
  const history = useHistory();
  const [searchOpen, setSearchOpen] = useState(false);
  let [activeCategories, setActiveCategories] = useState([]);

  let isStillActive = false;
  const addActiveCategory = (item, toggle = false) => {
    const ref = activeCategories.find(_ => _._uid == item._uid);
    if (ref) {
      setActiveCategories(
        activeCategories.map(stackItem => {
          stackItem.active =
            stackItem._uid !== item._uid
              ? false
              : toggle
              ? !stackItem.active
              : true;
          return stackItem;
        })
      );
    } else {
      setActiveCategories(state => [
        ...state.map(_ => ({ ..._, active: false })),
        { ...item, active: true }
      ]);
    }
  };
  const activeCategoryEnter = () => {
    isStillActive = true;
  };

  const resetActiveCategories = () => {
    setActiveCategories(
      activeCategories.map(stackItem => ((stackItem.active = false), stackItem))
    );
  };

  const resetActiveCategory = item => {
    if (!item) return;
    isStillActive = false;
    setTimeout(() => {
      if (!isStillActive) {
        setActiveCategories(
          activeCategories.map(
            stackItem => (
              (stackItem.active =
                stackItem._uid == item._uid ? false : stackItem.active),
              stackItem
            )
          )
        );
      }
    }, 50);
  };
  const campaign = storyblok.campaign?.[0];
  const isActiveCampaign =
    campaign &&
    (!campaign?.end_date || new Date(campaign?.end_date) > new Date());

  return (
    <>
      <Header>
        <TopBar withChannelSelector text={storyblok.usp_text} />
        <HeaderWrapper>
          <MaxWidthWrapper>
            <Inner>
              <div>
                <HomeLink to="/" aria-label={intl('Home')}>
                  {storyblok?.logo?.filename && (
                    <Branding
                      src={storyblok?.logo?.filename}
                      alt={storyblok?.logo?.alt || ''}
                      width="149"
                      height="42"
                    />
                  )}
                </HomeLink>
                <Navigation searchOpen={searchOpen}>
                  <ul>
                    {storyblok?.links.map(link => {
                      if (link.component == 'category') {
                        return (
                          <li key={link._uid}>
                            <Link
                              className={cx(
                                activeCategories.find(
                                  item => item._uid == link._uid
                                )?.active && 'active'
                              )}
                              to={handleLink(link.link)}
                              onTouchStart={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                if (
                                  activeCategories.find(
                                    item => item._uid == link._uid
                                  )?.active
                                ) {
                                  history.push(handleLink(link.link));
                                }
                                addActiveCategory(link, true);
                              }}
                              onTouchEnd={e => e.preventDefault()}
                              onClick={_ => addActiveCategory(link, true)}
                              onMouseLeave={_ => resetActiveCategory(link)}
                              onMouseEnter={_ => {
                                activeCategoryEnter(link);
                                addActiveCategory(link);
                              }}
                            >
                              {link.title}
                            </Link>
                            <SubNavigationSection
                              {...link}
                              className="hidden"
                            ></SubNavigationSection>
                          </li>
                        );
                      }
                      if (!link?.mobile) {
                        return (
                          <li key={link._uid}>
                            <Link
                              to={handleLink(link.link)}
                              onClick={resetActiveCategories}
                            >
                              {link.title}
                            </Link>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </Navigation>
              </div>
              <div className="cart-search">
                <SearchBar
                  placeholder={storyblok?.search_placeholder}
                  searchOpen={searchOpen}
                  setSearchOpen={setSearchOpen}
                />
                <CartButton />
              </div>
            </Inner>
            <AddedToCartWrapper />
          </MaxWidthWrapper>
        </HeaderWrapper>
        {activeCategories.length > 0 && (
          <>
            <Overlay
              onClick={resetActiveCategories}
              className={cx(
                activeCategories.filter(item => item.active).length > 0 &&
                  'active'
              )}
            />
            {activeCategories.map((item, i) => {
              return (
                <SubNavigationSection
                  key={item._uid}
                  onEnter={_ => activeCategoryEnter(item)}
                  onLeave={_ => resetActiveCategory(item)}
                  resetActiveCategories={resetActiveCategories}
                  {...item}
                ></SubNavigationSection>
              );
            })}
          </>
        )}
      </Header>
      {isActiveCampaign && campaign ? <TopBar campaign {...campaign} /> : null}
    </>
  );
};

export default Desktop;
