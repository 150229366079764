import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import StoryblokLanguageCodesQuery from './components/Storyblok/StoryblokLanguageCodesQuery.gql';

import {
  StandardCategoryPage as LoadableStandardCategoryPage,
  WindowedCategoryPage as LoadableWindowedCategoryPage
} from './components/CategoryPage/CategoryPage.loadable';
import { theme } from './components/Theme';
import { ProductPage as LoadableProductPage } from './components/ProductPage/ProductPage.loadable';
import { ContentPage as LoadableContentPage } from './components/ContentPage/ContentPage.loadable';

export const defaultLocale = 'en';

const config = {
  theme,
  apolloConfig: {
    useGraphQLProxyServer: true,
    shopid: process.env.REACT_APP_SHOP_ID || 'tretorn-b2c',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io/',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: StoryblokLanguageCodesQuery
      }
    ]
  },
  sentry: {
    clientDSN:
      'https://d18ec560d90849e3aee9a61c32458eb0@o570408.ingest.sentry.io/5956658'
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  additionalGtagTrackingIds: ['AW-123456789'],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: false,
  sentry: {
    clientDSN: process.env.FLIGHT_SENTRY_CLIENT_DSN,
    serverDSN: process.env.FLIGHT_SENTRY_SERVER_DSN,
    ignoreErrors: []
  },
  intl: {
    translations,
    defaultLocale,
    options: {}
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: process.env.REACT_APP_SINGLE_DOMAIN_MODE,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [LoadableProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [LoadableContentPage]
    }
  }
};
export default config;
