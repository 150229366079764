import React, { useState, useEffect } from 'react';
import { styled } from 'linaria/react';

import { theme } from '../../../Theme';

import { key } from '.';

const MenuWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .panel-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    transform: translateX(calc(var(--current) * -100%));
    transition: transform 0.4s;
  }

  .panel {
    position: relative;
    flex-shrink: 0;
    padding: 5rem 2rem 2rem;
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    font-size: 1.125rem;
    width: 100%;
    height: 100%;
    font-weight: 400;
    overflow: hidden;
    overflow-y: auto;

    a,
    .category-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      width: 100%;
      font-weight: inherit;
      color: inherit;
      padding: 0.25rem 0;
    }

    .category-button {
      border: none;
      background: none;
    }

    .featured,
    .title {
      font-weight: 700;
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    .title:not(.subcategory) {
      margin-bottom: 1.5rem;
    }

    .featured.subcategory,
    .category.subcategory,
    .category.customcategory,
    .category.header_link,
    .title.subcategory {
      font-weight: 600;
      font-size: 1.125rem;
      text-transform: initial;
    }

    .category.header_link {
      margin: 0.4rem 0;
      font-size: 1.125rem;
      text-transform: initial;
    }

    .featured.category + .header_link,
    .featured.subcategory + .category.subcategory,
    .category.subcategory + .category.subcategory {
      margin-top: 1.5rem;
    }

    .subcategories-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      width: 100%;
      font-weight: 400;
      li {
        overflow: hidden;
        a {
          font-size: 0.875rem;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .link:not(.category) {
      a {
        font-size: 0.875rem;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    li {
      svg {
        transform: rotate(-90deg);
      }
    }
    svg {
      width: 0.75rem;
      path {
        stroke: ${theme.colors.black};
      }
    }
  }
`;

let then = 0;
export const Panels = ({ root, cooldown = 200, ...rest }) => {
  const [state, setState] = useState({
    current: 0,
    panels: []
  });

  const pop = curr => {
    if (!Number.isInteger(curr)) {
      curr = state.current > 0 ? state.current - 1 : 0;
    }
    setState(state => ({ ...state, current: curr }));
  };

  const push = (component, props) => {
    if (!component) return;
    const now = Date.now();
    if (now - then < cooldown) return;
    then = now;
    let currentSlice = state.panels;
    if (state.current < currentSlice.length - 1) {
      currentSlice = state.panels.slice(0, state.current + 1);
    }
    setState({
      current: currentSlice.length,
      panels: [
        ...currentSlice,
        { Panel: component, key: key(), props, ...rest }
      ]
    });
  };

  useEffect(() => {
    push(root.component, root.props);
  }, []);

  return (
    <MenuWrapper style={{ '--current': state.current }}>
      <div className="panel-wrapper">
        {state.panels.map(({ Panel, key, props, drawer }, index) => {
          return (
            <Panel
              key={key}
              props={props}
              helpers={{
                push,
                pop,
                drawer,
                current: state.current,
                index
              }}
            />
          );
        })}
      </div>
    </MenuWrapper>
  );
};
