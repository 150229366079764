import React from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import Drawer from './Drawer';
import CartContent from './CartContent';
import cartQuery from './CartQuery.gql';

const CartDrawer = props => (
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <DrawerTarget id="cart-drawer">
          {drawer => (
            <Drawer isOpen={drawer.isOpen} right>
              <CartContent modal={drawer} result={result} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartDrawer;
