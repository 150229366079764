import { styled } from 'linaria/react';
import { theme } from '../Theme';

const MaxWidth = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  max-width: 1440px;

  ${theme.above.sm} {
    padding: 0 2rem;
  }

  ${theme.above.md} {
    padding: 0 3.5rem;
  }
  ${theme.below.sm} {
    p {
      font-size: 0.875rem;
    }
  }
`;

export default MaxWidth;
