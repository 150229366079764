import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

import TopBar from './TopBar';
import CartButton from '../../Cart/CartButton';
import MobileSearchBar from './MobileSearchBar';
import DesktopSearchBar from './DesktopSearchBar';
import BurgerButton from './BurgerButton';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import MobileMenu from './MobileNavigation';
import AddedToCartWrapper from '../../Cart/AddedToCartNotification/AddedToCartNotificationWrapper';

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  z-index: 3;
  display: contents;
  ${theme.above.sm} {
    position: sticky;
    top: 0px;
    display: block;
  }

  .main-header {
    box-shadow: 0px 1px 0px rgba(171, 171, 171, 0.25);
    ${theme.below.sm} {
      position: sticky;
      top: 0px;
    }
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: ${theme.colors.white};
    min-height: 54px;
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
    gap: 0.5rem;
    ${theme.above.sm} {
      gap: 1rem;
    }
  }
  ${theme.below.sm} {
    .searchbar {
      width: 2rem;
      justify-content: center;
      padding: 0;
    }
  }
`;
const Branding = styled('img')`
  height: auto;
  height: auto;
  max-width: 85%;
  margin: 0 auto;
`;

const ActionButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 38px;
  background: none;
`;

const HomeLink = styled(Link)`
  display: flex;
  height: 33px;
  ${theme.above.sm} {
    flex: 1;
    img {
      margin: 0 1.5rem;
    }
  }
  ${theme.below.sm} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  svg {
    height: 100%;
  }
`;

const SearchSection = styled('div')`
  position: sticky;
  top: 4px;
  z-index: 1;
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05);
  background-color: ${theme.colors.white};
`;

const Mobile = story => {
  if (!story) return null;
  const campaign = story.campaign?.[0];
  const isActiveCampaign =
    campaign &&
    (!campaign?.end_date || new Date(campaign?.end_date) > new Date());
  return (
    <>
      {story.usp_text ? <TopBar text={story.usp_text} /> : null}
      <Header>
        <div className="main-header">
          <ActionButtonWrapper>
            <BurgerButton />
          </ActionButtonWrapper>
          <HomeLink to="/">
            <Branding
              src={story?.logo?.filename}
              alt={story?.logo?.alt || ''}
              width="149"
              height="42"
            />
          </HomeLink>
          <div className="action-buttons">
            <MobileSearchBar
              className="searchbar"
              placeholder={story?.search_placeholder}
            />

            <ActionButtonWrapper>
              <CartButton />
            </ActionButtonWrapper>
          </div>
        </div>

        <AddedToCartWrapper />
      </Header>
      <MobileMenu {...story} />
      {campaign && isActiveCampaign ? <TopBar campaign {...campaign} /> : null}
    </>
  );
};

export default Mobile;
