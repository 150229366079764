import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

import t from '@jetshop/intl';
import ChannelSelector from '@jetshop/ui/ChannelSelector/ChannelSelector';
import CheckboxGroup from '@jetshop/ui/Checkbox/CheckboxGroup';

import Button from '../../../ui/Button';

import { theme } from '../../../Theme';
import flattenCountries from './flattenCountries';
import externalShops from './externalShops';

const CheckboxGroupWrapper = styled('div')`
  color: ${theme.colors.black};
  input ~ span {
    border: 1px solid ${theme.colors.tretornBlue};
  }
  label input:checked ~ span {
    background-color: ${theme.colors.tretornBlue};
  }
  svg {
    stroke-width: 0;
  }
`;

const CheckboxGroupContainer = styled(CheckboxGroup)`
  margin: 1rem 0;
  > span {
    font-size: 0.875rem;
    font-weight: 600;
  }
  > label {
    margin: 0;
    padding: 1rem 0;

    &:hover {
      input ~ span {
        background: none;
        border-color: ${theme.colors.black};
      }
    }
    span {
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.grey};
      border-radius: 2px;
      &:hover {
        background: none;
        border-color: ${theme.colors.black};
      }
    }
    input:checked ~ span {
      background-color: ${theme.colors.black};
      border-color: ${theme.colors.black};
    }
    svg {
      width: 9px;
      path {
        fill: ${theme.colors.white};
        stroke: none;
      }
    }
  }
`;

const ButtonGroup = styled('div')`
  > button {
    margin: 0.5rem 0;
    width: 100%;
  }
`;

const CancelButton = styled(Button)``;
const CommitButton = styled(Button)``;

const CheckboxGroups = ({
  updateChannel,
  selectedChannel,
  languageItems,
  onLanguageSelect,
  currencyItems,
  onCurrencySelect,
  hasChanged,
  hideTarget
}) => {
  return (
    <>
      <CheckboxGroupWrapper>
        <CheckboxGroupContainer
          selectedItem={selectedChannel.language.name}
          items={languageItems}
          handleChange={onLanguageSelect}
          data-testid="language-checkbox-group"
          groupLabel={t('Language')}
        />
        <CheckboxGroupContainer
          selectedItem={selectedChannel.currency.name}
          items={currencyItems}
          handleChange={onCurrencySelect}
          groupLabel={t('Currency')}
        />
      </CheckboxGroupWrapper>
      <ButtonGroup className="button-group">
        {hasChanged && (
          <CommitButton
            onClick={() => {
              updateChannel(selectedChannel);
            }}
          >
            {t('Update')}
          </CommitButton>
        )}
        <CancelButton secondary onClick={hideTarget}>
          {t('Close')}
        </CancelButton>
      </ButtonGroup>
    </>
  );
};

export const LanguageConfirmation = ({ props, helpers }) => {
  const [currentState, setCurrentState] = useState();
  const {
    channels,
    selectedChannel,
    updateChannel,
    channel,
    code,
    name,
    currencies
  } = props;

  const { push, pop } = helpers;

  useEffect(() => {
    setCurrentState({ channel, code, name });
  }, []);

  return (
    <ChannelSelector
      channels={channels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect
      }) => {
        if (
          !(
            currentState?.channel?.id === selectedChannel.id &&
            currentState?.name === selectedChannel.country.name
          )
        ) {
          onSelect(channel.id, null, null, code);
        }
        return (
          <CheckboxGroups
            {...{
              updateChannel,
              selectedChannel,
              languageItems,
              onLanguageSelect,
              currencyItems,
              onCurrencySelect,
              hasChanged,
              hideTarget: pop
            }}
          />
        );
      }}
    />
  );
};

const LanguageSelectorWrapper = styled('section')`
  padding: 15px 10px 25px;
  font-size: 0.875rem;
  width: 460px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 2px;

  .checkbox-group {
    margin: 30px 0 0;
    padding: 0 0.75rem;
    label {
    }
  }
  .button-group {
    display: flex;
    flex-direction: row-reverse;
    button {
      margin: 0 0.75rem;
      :first-child {
        margin-right: 0;
        min-width: 60%;
      }
      :last-child {
        margin-left: 0;
      }
    }
  }
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    li {
      button,
      a {
        display: inline-block;
        padding: 0.25rem 0.75rem;
        text-align: left;
        width: 100%;
        height: 100%;
        background: none;
        cursor: pointer;
        border-radius: 2px;
        text-decoration: none;
        color: ${theme.colors.black};
      }
      &.active {
        font-weight: 600;
        button {
          background: ${theme.colors.offWhite};
        }
      }
    }
  }
`;

export const LanguageSelector = ({
  channels,
  selectedChannel,
  updateChannel,
  hideTarget
}) => {
  const [countries] = useState(flattenCountries(channels));
  return (
    <LanguageSelectorWrapper>
      <ChannelSelector
        channels={channels}
        initialChannel={selectedChannel}
        render={({
          channels,
          currencyItems,
          languageItems,
          selectedChannel,
          onSelect,
          hasChanged,
          onCurrencySelect,
          onLanguageSelect
        }) => {
          const countriesList = [...countries, ...externalShops];

          return (
            <>
              <ul className="countries">
                {countriesList.map(
                  ({ name, code, channel, currencies, external }) => (
                    <li
                      key={channel.id + name}
                      className={cx(
                        channel.id === selectedChannel.id &&
                          name === selectedChannel.country.name &&
                          'active'
                      )}
                    >
                      {external ? (
                        <a href={external}>{name}</a>
                      ) : (
                        <button
                          onClick={e => {
                            onSelect(channel.id, null, null, code);
                          }}
                        >
                          {name}
                        </button>
                      )}
                    </li>
                  )
                )}
              </ul>
              <div className="checkbox-group">
                <CheckboxGroups
                  {...{
                    updateChannel,
                    selectedChannel,
                    languageItems,
                    onLanguageSelect,
                    currencyItems,
                    onCurrencySelect,
                    hasChanged,
                    hideTarget
                  }}
                />
              </div>
            </>
          );
        }}
      />
    </LanguageSelectorWrapper>
  );
};

export default LanguageSelector;
