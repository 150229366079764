/* eslint-disable */
import { css } from 'linaria';
import '@jetshop/ui/Theme/sanitizeCss';
import { theme } from './components/Theme';

css`
  :global() {
    ${resets};
    [class^='storyblok__'] {
      z-index: 1000 !important;
    }
  }
`;

const fallbackStyles = `
  font-family: "Avenir Next", "Avenir", "Nunito Sans", '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'Arial', 'sans-serif';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0;
  word-spacing: 0;
  font-weight: 400;
  visibility: visible;
`;

const resets = `
    html,
    body {
      height: 100%;
      font-family: "Avenir Next", "Avenir", "Nunito Sans", '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', 'Arial', 'sans-serif';
    }
    html,
    body,
    #root {
      min-height: 100%;
    }
    #root {
      display: flex;
      flex-direction: column;
    }
    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
    }
    ol,
    ul,
    h4,
    h3,
    h2,
    h1 {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    p,
    dd {
      margin: 0;
      padding: 0;
    }
    input {
      -webkit-appearance: none;
    }
    .ReactModal__Body--open {
      overflow: hidden;
    }
    .ReactModal__Overlay--after-open {
      overflow: scroll;
      background: rgba(0,0,0,0.1) !important;
    }
    body {
      ${fallbackStyles};
      -webkit-font-smoothing: antialiased;
    }
    /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
    .findify-layouts--autocomplete--dropdown__fullscreen {
      z-index: 1;
      position: fixed !important;
      top: revert !important;
      z-index: 5;
      margin-top: 1px;
      ${theme.below.md}{
        margin-top: -20px;
        border-top: 1px solid ${theme.colors.lightGrey};
      }
    }
`;
