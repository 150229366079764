import React, { useContext, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import flattenCountries from '../ChannelSelector/flattenCountries';
import PanelWrapper from './PanelWrapper';

import { ReactComponent as ChevronSVG } from '../../../../svg/chevron.svg';
import { LanguageConfirmation } from '../ChannelSelector/Selector';
import { key } from '.';
import externalShops from '../ChannelSelector/externalShops';

const LanguageSelectorWrapper = styled('div')`
  font-size: 0.875rem;
  color: ${theme.colors.text};

  header {
    color: ${theme.colors.black};
    font-size: 1.125rem;
    font-weight: 600;
  }

  .language-list {
    margin: 2rem 0;
    li {
      margin: 0.25rem 0;
      button,
      a {
        display: flex;
        padding: 0.25rem 0;
        align-items: center;
        text-align: left;
        justify-content: space-between;
        background-color: transparent;
        width: 100%;
        color: ${theme.colors.black};
        font-size: 0.875rem;
        font-weight: 400;
        cursor: pointer;
      }
      &.active {
        font-weight: 600;
      }
      svg {
        transform: rotate(-90deg);
      }
    }
  }
`;

const Strong = ({ children }) => <strong key={key()}>{children}</strong>;

export const LanguageSelectorPanel = ({ props, helpers }) => {
  const { push, pop } = helpers;
  const { channels, selectedChannel, updateChannel } = useContext(
    ChannelContext
  );
  const [countries] = useState(flattenCountries(channels));
  const countriesList = [...countries, ...externalShops];
  return (
    <PanelWrapper {...helpers} back>
      <LanguageSelectorWrapper>
        <section className="info-box">
          <header>
            {t(
              `${selectedChannel.country.name} (${selectedChannel.currency.name})`
            )}
          </header>
          <p>
            {t.rich(
              `Du handlar för närvarande från <Strong>${selectedChannel.country.name}</Strong> med valutan <Strong>${selectedChannel.currency.name}</Strong>.`,
              { Strong }
            )}
          </p>
        </section>

        <section className="language-list">
          <header>{t('Choose country')}</header>
          <ul>
            {countriesList.map(
              ({ name, code, channel, currencies, external }) => (
                <li key={channel.id + name}>
                  {external ? (
                    <a href={external}>
                      {name} <ChevronSVG />
                    </a>
                  ) : (
                    <button
                      onClick={e => {
                        push(LanguageConfirmationWrapper, {
                          name,
                          channels,
                          selectedChannel,
                          updateChannel,
                          channel,
                          code,
                          currencies
                        });
                      }}
                    >
                      {name} <ChevronSVG />
                    </button>
                  )}
                </li>
              )
            )}
          </ul>
        </section>
      </LanguageSelectorWrapper>
    </PanelWrapper>
  );
};

export const LanguageConfirmationWrapper = ({ props, helpers }) => {
  return (
    <PanelWrapper {...helpers} back>
      <strong className="title subcategory">{props.name}</strong>
      <LanguageConfirmation {...{ props, helpers }} />
    </PanelWrapper>
  );
};
