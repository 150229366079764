import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { Above } from '@jetshop/ui/Breakpoints';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import CartDrawer from '../../Cart/CartDrawer';

import { useStoryblokInternationalizedPathname } from '../../Storyblok/storyblok';
import StoryblokRouteQuery from '../../Storyblok/queries/StoryblokRouteQuery.gql';

export const Header = story => {
  return (
    <>
      <Above breakpoint="md">
        {match =>
          match ? <DesktopHeader {...story} /> : <MobileHeader {...story} />
        }
      </Above>
      <CartDrawer />
    </>
  );
};

export default function StandaloneHeader() {
  const location = useLocation();
  let { data } = useQuery(StoryblokRouteQuery, {
    variables: { path: useStoryblokInternationalizedPathname('header') }
  });

  if (location.pathname === '/header') return null;

  if (!data?.StoryblokContentNode?.content) return null;
  return <Header {...data.StoryblokContentNode.content} />;
}
