import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../../Theme';
import { H4 } from '../../ui/Typography';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Button, { ExternalLinkButton } from '../../ui/Button';
import cartQuery from '../../Cart/CartQuery.gql';
import { ReactComponent as CloseSVG } from '../../../svg/close.svg';
import { FindifyRecommendations } from '../../Findify';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2rem 2rem;
  background: ${theme.colors.white};
  box-shadow: 10px 10px 33px 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;

  .close {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    width: 12px;
    height: 12px;
    background: none;
    svg {
      display: block;
      width: 12px;
      height: 12px;
    }
  }

  ${theme.below.xs} {
    padding: 1rem;

    .close {
      position: absolute;
      padding: 0;
      top: 1.25rem;
      right: 1rem;
    }
  }
`;

const SelectedVariants = styled('ul')`
  margin: 1rem 0;
`;
const ProductImageWrapper = styled('div')`
  width: 110px;
  height: 110px;
  ${theme.above.md} {
    width: 200px;
    height: 200px;
  }
`;
const Product = styled('div')`
  display: grid;
  margin: 1rem 0;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;

const ProductDetails = styled('div')`
  font-size: 0.875rem;
  line-height: 1.58;
`;

const Header = styled('header')``;
const Title = styled(H4)``;

const PriceWrapper = styled(Price)`
  margin: 0.5rem 0;
  text-transform: uppercase;
`;

const ButtonGroup = styled('div')`
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  gap: 1rem;
  > .button {
    width: 100%;
  }
`;

const ProductName = styled('p')`
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: ${theme.colors.black};
`;
const Error = styled('p')``;

const ExternalCartButton = styled(ExternalLinkButton)`
  &.disabled {
    pointer-events: none;
  }
`;

const GoToCartButton = ({ cart, children }) => {
  const channel = useContext(ChannelContext);
  const track = useTracker();

  let checkoutUrl = '';
  if (cart?.externalCheckoutUrl && channel?.selectedChannel.country.code) {
    checkoutUrl = `${cart.externalCheckoutUrl}&channelCountry=${channel?.selectedChannel.country.code}`;
  }

  return (
    <ExternalCartButton
      className={cx('button', !checkoutUrl ? 'disabled' : 'action')}
      href={checkoutUrl}
      onClick={() => {
        track(trackCartCheckoutEvent({ cart }));
      }}
    >
      {children}
    </ExternalCartButton>
  );
};

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  quantity,
  price,
  error
}) => {
  const image = selectedVariation?.images[0] || product.images[0];
  const variantOptions = product.variants.options;

  if (error) {
    return (
      <Container>
        <section>
          <Header>{t('Failed adding to bag')}</Header>
          <Error>
            {t('An error occurred. Details:')}
            <ul>
              {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
                error.graphQLErrors.map(({ message, locations, path }) => (
                  <li key={message}>{t(message)}</li>
                ))
              ) : (
                <li>{t(error.message)}</li>
              )}
            </ul>
          </Error>
        </section>
      </Container>
    );
  }
  return (
    <Container data-testid="product-toast">
      <section>
        <Header>
          {quantity === 1 ? (
            <Title>{t('Added to bag')}</Title>
          ) : (
            <Title>
              {t.rich('Added {quantity} items to bag', { quantity })}
            </Title>
          )}
          <button className="close" onClick={closeToast}>
            <CloseSVG />
          </button>
        </Header>

        <Product>
          {product.images?.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={image.url}
                sizes={200}
                aspect={'1:1'}
                alt={image.alt}
                quality={80}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            <ProductName>{product.name}</ProductName>
            <PriceWrapper
              price={price.price}
              previousPrice={price.previousPrice}
            />
            {selectedVariation && (
              <SelectedVariants>
                {variantOptions.map((option, idx) => (
                  <li>
                    {option.name}:{' '}
                    {selectedVariation.values[idx].replace('_', '/')}
                  </li>
                ))}
                <li>
                  {t('Quantity')}: {quantity}
                </li>
              </SelectedVariants>
            )}
          </ProductDetails>
        </Product>
        <ButtonGroup>
          <GoToCartButton cart={cart}>{t('To checkout')}</GoToCartButton>
          <Button onClick={closeToast} secondary className="button">
            {t('Continue shopping')}
          </Button>
        </ButtonGroup>
        <FindifyRecommendations recIdString={'product-findify-rec-7'} />
      </section>
    </Container>
  );
};

const ProductToastWrapper = props => {
  return (
    <CartProvider query={cartQuery}>
      {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
    </CartProvider>
  );
};

export default ProductToastWrapper;
